import React, { ReactElement } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { Box, Text, Flex } from '@chakra-ui/core';
import { s3BucketUrl } from 'config';
import { useIsThemeBreakPoint } from 'contexts/Breakpoints';
import { ReportInfoBlock, BucketItem } from 'typings';
import { DownloadSVG } from 'components/Icons';
import _ from 'lodash';

const REPORT_INFO_BLOCK_QUERY = gql`
  query getReport($slug: String!, $year: Int!) {
    getReport(slug: $slug) {
      id
      infoBlocks(year: $year) {
        id
        title
        text
      }
    }
  }
`;

interface Props {
  year: number;
  slug: string;
}

const DataBookInfoBlock: React.FC<Props> = ({ year, slug }): ReactElement => {
  const { data, loading } = useQuery(REPORT_INFO_BLOCK_QUERY, {
    variables: { slug, year },
  });

  // sets needed variables for below. filters out and sets specific items.
  let infoBlocks: any;
  let published: any;
  if (data) {
    infoBlocks = data.getReport.infoBlocks.filter((i: ReportInfoBlock) => {
      if (i.title.toLowerCase() === 'published') {
        published = i;
        return false;
      }
      return true;
    });
  }

  return (
    <Box padding="40px" paddingTop="24px">
      <Text fontSize="36px" fontWeight="bold" color="black" marginBottom="16px">
        School Year: {`${year - 1} - ${year}`}
      </Text>
      {published && (
        <Text fontSize="16px" color="gray.800" marginBottom="16px">
          Published {published.text}
        </Text>
      )}
      <Text fontSize="20px" fontWeight="bold" marginBottom="24px">
        {year} - Selected Highlights
      </Text>
      {infoBlocks && infoBlocks.length > 0 ? (
        <InfoBlocks blocks={infoBlocks} />
      ) : (
        <Box height="200px" marginTop="32px">
          <Text>No Information Found for that Year. </Text>
        </Box>
      )}
      <ReportButtons year={year} slug={slug} />
    </Box>
  );
};

const InfoBlocks: React.FC<{ blocks: ReportInfoBlock[] }> = ({ blocks }): ReactElement => {
  return (
    <>
      {blocks.map(block => (
        <Box key={block.id}>
          <Text fontSize="16px" fontWeight="bold" marginBottom="8px">
            {block.title}:
          </Text>
          <Text fontSize="16px" color="gray.900" marginBottom="16px">
            {block.text}
          </Text>
        </Box>
      ))}
    </>
  );
};

const makeUrl = (slug: string, year: number, file?: string): string => {
  return `${s3BucketUrl}/${slug}/${year}/${file}`;
};

const GET_BUCKET_CONTENTS = gql`
  query getBucketContents($slug: String!, $year: Int!) {
    getBucketContents(slug: $slug, year: $year) {
      items {
        filename
      }
    }
  }
`;

const ReportButtons: React.FC<Props> = ({ year, slug }): ReactElement => {
  const { data, loading } = useQuery(GET_BUCKET_CONTENTS, {
    variables: { slug, year },
  });
  const laptop = useIsThemeBreakPoint(1);

  let items = data?.getBucketContents?.items.map((i: any) => {
    return {
      ...i,
      ...makeButtonTextFromFilename(i),
    };
  });

  items = _.orderBy(items, ['order']);

  return (
    <Flex flexDirection={laptop ? 'row' : 'column'} flexWrap={'wrap'} alignItems={laptop === false ? 'center' : ''}>
      {items &&
        items.map(
          (item: BucketItem): ReactElement => (
            <DownloadReportButton key={item.filename} slug={slug} year={year} item={item as any} />
          ),
        )}
    </Flex>
  );
};

function makeButtonTextFromFilename(i: any) {
  let text;
  let order;
  if (i.filename.includes('AppendixCData') || i.filename.includes('AppendixData')) {
    text = 'Appendix C Data Table';
    order = 2;
  } else if (i.filename.includes('SuptRpt')) {
    text = `Superintendent's Annual Report`;
    order = 1;
  } else if (i.filename.includes('HIDOEDatabook')) {
    text = `HIDOE Databook`;
    order = 1;
  } else {
    text = i.filename;
    order = 99;
  }

  return { text, order };
}

const DownloadReportButton: React.FC<Props & { item: BucketItem & { text: string } }> = ({
  slug,
  year,
  item,
}): ReactElement => {
  return (
    <a href={makeUrl(slug, year, item.filename)} target="_blank" rel="noopener noreferrer">
      <Box
        marginRight="16px"
        marginBottom="16px"
        border="1px solid"
        borderRadius="8px"
        borderColor="primary"
        padding="12px"
        maxWidth="350px"
      >
        <Flex align="center">
          <Box flex="1">
            <DownloadSVG color="black" />
          </Box>
          <Flex flex="4" padding="16px" flexDir="column" justify="space-between">
            <Text color="primary" fontWeight="bold">
              DOWNLOAD
            </Text>
            <Text color="primary">{item.text}</Text>
          </Flex>
        </Flex>
      </Box>
    </a>
  );
};

export default DataBookInfoBlock;
