import React, { ReactElement } from 'react';
import { Grid, Flex, Text } from '@chakra-ui/core';
import { Report } from 'typings';
import { BeatLoader } from 'react-spinners';
import ReportTile from 'components/ReportsGrid/ReportTile';
import _ from 'lodash';

const REPORT_ORDER: { [index: string]: number } = {
  essa: 1,
  ssir: 2,
  'trend-report': 3,
  sqs: 4,
  'hidoe-data-book': 5,
  'annual-report': 6,
  'strive-hi-accountability': 7,
  'student-group-performance': 8,
};

interface ReportGridProps {
  reports: Report[];
  loading: boolean;
}
const ReportGrid: React.FC<ReportGridProps> = ({ reports, loading }): ReactElement => {
  function truncateText(text: string): string {
    const sentences = text.split('. ');
    return `${sentences[0]}. ${sentences[1]}...`;
  }

  const orderedReports = _.sortBy(
    reports?.map(r => ({ ...r, order: REPORT_ORDER[r.slug] })),
    ['order'],
  );
  const currentReports = orderedReports?.filter(r => r.current);
  const previousReports = orderedReports?.filter(r => !r.current);

  return (
    <>
      {currentReports && (
        <>
          <Text as="h3" color="primary" marginBottom=".5em" fontWeight="bold">
            Current Reports
          </Text>
          <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap="6">
            {currentReports.map(
              (r): ReactElement => (
                <ReportTile
                  maxWidth={'700px'}
                  key={r.slug}
                  name={r.name}
                  shortDescription={r.description && `${truncateText(r.description)}`}
                  lastUpdated={r.lastUpdated}
                  slug={r.slug}
                />
              ),
            )}
            {loading && (
              <Flex width="100%" justify="center" align="center" minHeight="200px">
                <BeatLoader loading={loading} color="gray" />
              </Flex>
            )}
          </Grid>
        </>
      )}
      {previousReports && (
        <>
          <Text as="h3" color="primary" marginTop="2em" marginBottom=".5em" fontWeight="bold">
            Previous Reports
          </Text>
          <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap="6">
            {previousReports.map(
              (r): ReactElement => (
                <ReportTile
                  maxWidth={'700px'}
                  key={r.slug}
                  name={r.name}
                  shortDescription={r.description && `${truncateText(r.description)}`}
                  lastUpdated={r.lastUpdated}
                  slug={r.slug}
                />
              ),
            )}
            {loading && (
              <Flex width="100%" justify="center" align="center" minHeight="200px">
                <BeatLoader loading={loading} color="gray" />
              </Flex>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default ReportGrid;
