import { theme, ITheme } from '@chakra-ui/core';

const mergedTheme: ITheme = {
  // chakra-ui defaults:
  ...theme,
  breakpoints: [30, 48, 71, 80],
  // TODO: follow up on github to see if this works.
  //  breakpoints: {
  //   sm: '30em',
  //   md: '48em',
  //   lg: '62em',
  //   xl: '80em',
  // },
  sizes: {
    ...theme.sizes,
    maxWidth: '950px',
  },
  fonts: {
    body: '"Karla", sans-serif',
    heading: '"Karla", sans-serif',
    mono: 'Menlo, monospace',
  },
  fontWeights: {
    ...theme.fontWeights,
    normal: 400,
    medium: 400,
    bold: 700,
  },
  fontSizes: {
    mobile: {
      h1: '40px',
      h2: '32px',
      h3: '24px',
      h4: '18px',
      small: '12px',
      body: '14px',
      big: '16px',
    },
    desktop: {
      h1: '50px',
      h2: '36px',
      h3: '24px',
      h4: '20px',
      small: '14px',
      body: '16px',
      big: '18px',
    },
  },
  colors: {
    ...theme.colors,
    info: '#25A9EF',
    danger: '#EB5757',
    success: '#10C971',
    warning: '#F2994A',
    status: {
      info: '#25A9EF',
      danger: '#EB5757',
      success: '#10C971',
      warning: '#F2994A',
    },
    // kim's colors:
    primary: '#2965B3',
    secondary: '#214261',
    accent: '#4F4F4F',
    black: '#2C2C2C',
    white: '#FFFFFF',
    darkNavy: '#0D2436',
    footerGray: '#959EAD',
    textGray: '#333333',
    softGray: '#E5EAF4',
    // end kim's colors
    gray: {
      100: '#FBFAFD',
      400: '#EAE6EF',
      800: '#8F879D',
      900: '#4E4A58',
    },
    purple: {
      50: '#FAF5FF',
      200: '#D6BCFA',
      400: '#9075E5',
      700: '#553C9A',
      800: '#44337A',
      900: '#322659',
    },
    pink: {
      50: '#FFF5F7',
      100: '#FED7E2',
      200: '#FBB6CE',
      300: '#F687B3',
      500: '#D53F8C',
      700: '#97266D',
    },
    blue: {
      50: '#EBF8FF',
      100: '#CEEDFF',
      200: '#90CDF4',
      400: '#4299E1',
      600: '#2A69AC',
    },
    yellow: {
      light: '#FFF4D9',
    },
    green: {
      light: '#DDF9D3',
    },
  },
  shadows: {
    lighter: '0px 2px 4px rgba(87, 87, 87, 0.08)',
    light: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    medium: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    dark: '0px 16px 16px rgba(87, 87, 87, 0.2)',
    up: '0px -8px 8px rgba(87, 87, 87, 0.08)',
  },
};

export default mergedTheme;
