import React from 'react';
import { Global, css, SerializedStyles } from '@emotion/core';

const globals = (theme: any): SerializedStyles => css`
  body,
  button,
  p,
  a,
  span,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${theme.fonts.body};
    color: ${theme.colors.black};
    line-height: 130%;
    word-break: normal;
  }
  
  /* Needed a way to override the label style for selectMenu */
  .whiteLabels label {
    color: white !important;
  }

  h1 {
    font-size: 3.1rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  p {
    font-size: '100%';
    /* font-size: ${theme.fontSizes.mobile.body}; */
    margin: 0;
    padding: 0;
  }

  button:hover,
  a:hover {
    cursor: pointer;
  }

  .hoverPointer:hover {
    cursor: pointer;
  }
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/

  button:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }

  a:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    box-shadow: none;
    /* outline: -webkit-focus-ring-color auto 5px; */
  }

/* TODO: remove if not needed for kims design 
  @media only screen and (min-width: ${theme.breakpoints[2]}em) {
    h1 {
      font-size: 64px;
    }

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 20px;
    }

    p {
      font-size: ${theme.fontSizes.desktop.body};
    }
  } */
`;

/* The Component */
const GlobalStyles: React.FC = () => {
  return <Global styles={globals} />;
};

export default GlobalStyles;
