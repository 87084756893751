import React, { ReactElement } from 'react';
import { Box, Text, List, ListItem, PseudoBox } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

import * as Types from './types';

interface NavSubMenu {
  sections: Types.MenuSections;
  rootPath: string;
}

/**
 * Renders the appropriate sub menu style based on if a section title is present.
 */
const NavSubMenu: React.FC<NavSubMenu> = ({ sections, rootPath }): ReactElement => {
  return (
    <Box color="white" maxWidth="300px">
      {sections.map(
        (s: Types.MenuSection, i: number): ReactElement => {
          if (s.sectionTitle) {
            return <MenuSectionWithTitle {...s} rootPath={rootPath} key={s.sectionTitle} />;
          }
          return <MenuSection {...s} rootPath={rootPath} key={i} />;
        },
      )}
    </Box>
  );
};

interface MenuSectionProps extends Types.MenuSection {
  rootPath: string;
}

/**
 * Renders a menu section with a title and bulleted list.
 */
const MenuSectionWithTitle: React.FC<MenuSectionProps> = ({ sectionTitle, items, rootPath }): ReactElement => {
  return (
    <Box>
      <Box backgroundColor="#16314a" borderColor="#133758" borderWidth="0" borderBottomWidth="1px" borderStyle="solid">
        <Text padding="16px" margin="0" color="white" fontWeight="bold" fontSize="16px" lineHeight="130%">
          {sectionTitle}
        </Text>
      </Box>
      <List styleType="none" margin="0">
        {items.map(i => (
          <ListItem
            padding="16px"
            borderBottom="1px solid #16314a"
            wordBreak="break-word"
            color="rgba(255,255,255,.6)"
            key={i.title}
          >
            <Box display="inline">
              <PseudoBox
                as={props => (
                  <Link {...props} to={`${rootPath}/${i.slug}`}>
                    {i.title}
                  </Link>
                )}
                fontSize=".9rem"
                fontWeight="normal"
                lineHeight="130%"
                color="white"
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

/**
 * Renders a menu section without titles, as border-separated links.
 */
const MenuSection: React.FC<MenuSectionProps> = ({ items, rootPath }): ReactElement => {
  return (
    <>
      {items.map((i, index) => (
        <Box
          borderColor="purple.400"
          borderWidth="0"
          borderBottomWidth={index === items.length - 1 ? '0px' : '1px'}
          borderStyle="solid"
          key={i.title}
        >
          <PseudoBox
            as={props => (
              <Link {...props} to={`${rootPath}${i.slug}`}>
                {i.title}
              </Link>
            )}
            display="inline-block"
            fontSize="16px"
            fontWeight="normal"
            lineHeight="130%"
            padding="16px"
            color="white"
          />
        </Box>
      ))}
    </>
  );
};

export default NavSubMenu;
