// eventual roles: 'admin' | 'statewide' | 'district' | 'complexArea' | 'complex' | 'school';
// implemented roles:
export enum UserRoleEnum {
  ADMIN = 'ADMIN',
}

export enum ReportType {
  SCHOOL = 'SCHOOL',
  STATE = 'STATE',
}

export enum LanguageEnum {
  ENGLISH = 'ENGLISH',
  CEBUANO = 'CEBUANO',
  CHINESE_SIMPLIFIED = 'CHINESE_SIMPLIFIED',
  CHINESE_TRADITIONAL = 'CHINESE_TRADITIONAL',
  CHUUKESE = 'CHUUKESE',
  HAWAIIAN = 'HAWAIIAN',
  ILOCANO = 'ILOCANO',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  MARSHALLESE = 'MARSHALLESE',
  SAMOAN = 'SAMOAN',
  SPANISH = 'SPANISH',
  TAGALOG = 'TAGALOG',
  TONGAN = 'TONGAN',
  VIETNAMESE = 'VIETNAMESE',
}
