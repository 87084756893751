import { useState, useEffect } from 'react';
import { gql, ApolloError } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useMakeToast } from 'components/Toaster/Toaster';
import { Report } from '../typings';

export const REPORT_QUERY = gql`
  query getReport($slug: String!) {
    getReport(slug: $slug) {
      name
      slug
      description
      externalLink
      lastUpdated
      defaultYear {
        year
      }
      availableYears {
        year
        range
      }
      infoBlocks(year: 9999) {
        id
        section
        title
        text
        link
      }
    }
  }
`;

const useReport = (slug: string): { report: Report; setReport: any; error?: ApolloError; loading: boolean } => {
  const makeToast = useMakeToast();
  const { data, error, loading } = useQuery(REPORT_QUERY, {
    variables: { slug },
    skip: !slug,
    fetchPolicy: 'cache-and-network',
  });

  const [report, setReport] = useState(data && data.getReport);

  useEffect(() => {
    setReport(data && data.getReport);
  }, [data]);

  if (error) {
    makeToast({
      status: 'danger',
      text: 'Use Report Error',
      subText: 'there was an issue getting the information for this report.',
    });
  }

  return { report, setReport, error, loading };
};

export default useReport;
