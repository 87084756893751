import React, { ReactElement } from 'react';
import { Text, Box, Flex, Image } from '@chakra-ui/core';
import { useHistory } from 'react-router-dom';
import MainBackgroundPNG from 'assets/images/mainBackground.png';
import BackgroundImage from 'components/BackgroundImage';
import { ReportSearchProvider } from 'components/Forms/ReportSearchProvider';
import researchDocumentImage from 'assets/images/researchDocumentSmall.png';
import HomeReportSearchForm, { HomeReportSearchFormOnSubmitFn } from 'components/Forms/HomeReportSearchForm';
import { useIsThemeBreakPoint } from 'contexts/Breakpoints';

import CapImg from 'assets/images/cap.png';

import useThemeColor from 'theme/useThemeColor';
import { TitleText, DOEText } from './TitleText';

/* negative margin for a better looking curve (less vertical) - the parent div must have overflow: hidden */
const roundedBottomStyle: any = {
  borderRadius: '0 0 50% 50%',
  width: '120vw',
  marginLeft: '-10vw',
  marginRight: '-10vw',
  overflow: 'hidden',
};

/* The Component */
const MainSection: React.FC = (): ReactElement => {
  const isLaptop = useIsThemeBreakPoint(1);
  const secondaryColor = useThemeColor('secondary');

  return (
    <>
      <Box width="100vw" overflow="hidden">
        <BackgroundImage
          src={MainBackgroundPNG}
          style={{ backgroundColor: secondaryColor, maxHeight: isLaptop ? '400px' : '600px', ...roundedBottomStyle }}
        >
          <Box paddingTop="100px" textAlign="center" paddingX=".5em">
            <DOEText />
            <TitleText />
          </Box>
          <Image src={CapImg} position="relative" bottom="50px" maxWidth="40%" alt="" />
        </BackgroundImage>
      </Box>
      <Box>
        <Box position="relative" top={-100}>
          <Flex justifyContent="center">
            <Box
              backgroundColor="white"
              borderRadius="16px"
              shadow="medium"
              paddingX="26px"
              paddingBottom="26px"
              marginBottom="-32px"
            >
              <SearchReportSection />
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default MainSection;

const SearchReportSection: React.FC = (): ReactElement => {
  const laptop = useIsThemeBreakPoint(1);
  const history = useHistory();

  const navToReportPage: HomeReportSearchFormOnSubmitFn = ({ report, year, entity }): void => {
    let path = `/reports/${report}`;

    const queryParams = [];
    if (year) queryParams.push(`year=${year}`);
    if (entity) queryParams.push(`entity=${entity}`);
    if (queryParams.length > 0) {
      path += `?${queryParams.join('&')}`;
    }

    history.push(path);
  };

  return (
    <Flex width={laptop ? '360px' : '300px'} direction="column" align="center">
      <Image width="50px" position="relative" top="-25px" src={researchDocumentImage} alt="Search Reports" />
      <Text as="span" fontSize="1.rem" color="secondary">
        Current Reports
      </Text>
      <ReportSearchProvider>
        <HomeReportSearchForm onSubmit={navToReportPage} />
      </ReportSearchProvider>
    </Flex>
  );
};
