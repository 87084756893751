import * as Types from './types';

/** NAVListItem SCHEME:
 * - the list of nav items on the main menu.
 * - needs a title and a navPath (the internal relative path.).
 * - optional external navPath and set isExternal:true.
 * - sections:
 *    - list of nested sub-sections in the chevron dropdown menu.
 *    - needs a sectionTitle, and then a list of items with title and slug.
 * - order of items, sections, nested items determined by this order below.
 */

const NavListItems: Types.NavItemsArray = [
  { navTitle: 'Home', navPath: '/' },
  {
    navTitle: 'Reports',
    navPath: '/reports',
    sections: [
      {
        sectionTitle: 'Current Reports',
        items: [
          { title: 'Strive Hi Performance Report', slug: 'strivehi-performance' },
          { title: 'Every Student Succeeds Act', slug: 'essa' },
          { title: 'School Status & Improvement Report', slug: 'ssir' },
          { title: 'Trend Report', slug: 'trend-report' },
          { title: 'School Quality Survey', slug: 'sqs' },
          { title: 'HIDOE Data Book', slug: 'hidoe-data-book' },
        ],
      },
      {
        sectionTitle: 'Previous Reports',
        items: [
          { title: `Superintendent's Annual Report 2015 and Prior`, slug: 'annual-report' },
          { title: 'Strive HI Accountability', slug: 'strive-hi-accountability' },
          { title: 'Student Group Performance Report', slug: 'student-group-performance' },
        ],
      },
    ],
  },
  { navTitle: 'Schools', navPath: '/schools' },
  { navTitle: 'Accountability Data Center', navPath: '/adc-redirect' },
  // { navTitle: 'Accountability Data Center', navPath: 'https://adc.hidoe.us/#/', isExternal: true },
  { navTitle: 'About', navPath: '/about' },
  { navTitle: 'Contact Us', navPath: '/contact' },
];

export default NavListItems;
