import React, { ReactNode, CSSProperties, ReactElement } from 'react';
import { Box } from '@chakra-ui/core';

interface ImageBGProps {
  src: string;
  children: ReactNode;
  style?: CSSProperties;
}
const BackgroundImage: React.FC<ImageBGProps> = ({ src, children, style }): ReactElement => {
  return (
    <Box style={{ ...style }} bgImage={`url(${src})`} bgPos="50% 25%" bgSize="cover" backgroundRepeat="no-repeat">
      <Box margin="0 auto">{children}</Box>
    </Box>
  );
};

export default BackgroundImage;
