/* eslint-disable no-underscore-dangle */
import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { BoxProps } from '@chakra-ui/core';
import { EntityFilters } from 'components/Forms/ReportSearchProvider';
import { alphabetize } from 'utilityFns/alphabetize';
import SelectMenu from './SelectMenu';
import { BucketItem, Complex, ComplexArea, District, Entity } from '../../typings';
import { LanguageEnum } from '../../typings/arch';

const GET_ENTITIES = gql`
  query {
    getDistricts {
      __typename
      id
      code
      name
      longName @client
      complexAreas {
        id
        __typename
        code
        name
        longName @client
        complexes {
          id
          __typename
          code
          name
          longName @client
        }
      }
    }
  }
`;

function distinct(value: any, index: any, self: any): boolean {
  return self.indexOf(value) === index;
}

type GroupOptions = { label: string; options: any[] };
function mapDistrictsToOptions(districts: District[], reportType?: string, year?: number): GroupOptions[] {
  const complexAreas = districts.flatMap(district => district.complexAreas).filter(distinct);
  const complexes = complexAreas.flatMap(complexArea => complexArea.complexes);

  const excludedStateFilterReports = ['ssir'];
  const excludedNonStateFilterYears: { [index: string]: number[] } = {
    'student-group-performance': [2013, 2015, 2016],
  };
  const includedTranslationFilterReports = ['sqs'];
  const translationFilterStartYear = 2020;
  return [
    ...(reportType && excludedStateFilterReports.includes(reportType)
      ? []
      : [
          {
            label: 'State',
            options: [{ __typename: 'Entity', id: 'Entity-999', code: 999, longName: 'State of Hawaii' }],
          },
        ]),
    ...(reportType && year && excludedNonStateFilterYears[reportType]?.includes(year)
      ? []
      : [
          {
            label: 'Districts',
            options: districts.sort((a, b) => alphabetize(a.name, b.name)),
          },
          {
            label: 'Complex Areas',
            options: complexAreas.sort((a, b) => alphabetize(a.name, b.name)),
          },
          {
            label: 'Complexes',
            options: complexes.sort((a, b) => alphabetize(a.name, b.name)),
          },
        ]),
    ...(reportType &&
    includedTranslationFilterReports.includes(reportType) &&
    year &&
    year >= translationFilterStartYear
      ? [
          {
            label: 'Translations',
            options: [
              {
                __typename: 'Translation',
                code: 1000,
                id: LanguageEnum.CEBUANO,
                longName: 'Cebuano',
              },
              {
                __typename: 'Translation',
                code: 1002,
                id: LanguageEnum.CHINESE_SIMPLIFIED,
                longName: 'Chinese simplified',
              },
              {
                __typename: 'Translation',
                code: 1004,
                id: LanguageEnum.CHINESE_TRADITIONAL,
                longName: 'Chinese traditional',
              },
              {
                __typename: 'Translation',
                code: 1006,
                id: LanguageEnum.CHUUKESE,
                longName: 'Chuukese',
                },
             {
                __typename: 'Translation',
                code: 1027,
                id: LanguageEnum.ENGLISH,
                longName: 'English',
              },
              {
                __typename: 'Translation',
                code: 1008,
                id: LanguageEnum.HAWAIIAN,
                longName: 'Hawaiian',
              },
              {
                __typename: 'Translation',
                code: 1010,
                id: LanguageEnum.ILOCANO,
                longName: 'Ilocano',
              },
              {
                __typename: 'Translation',
                code: 1012,
                id: LanguageEnum.JAPANESE,
                longName: 'Japanese',
              },
              {
                __typename: 'Translation',
                code: 1014,
                id: LanguageEnum.KOREAN,
                longName: 'Korean',
              },
              {
                __typename: 'Translation',
                code: 1016,
                id: LanguageEnum.MARSHALLESE,
                longName: 'Marshallese',
              },
              {
                __typename: 'Translation',
                code: 1018,
                id: LanguageEnum.SAMOAN,
                longName: 'Samoan',
              },
              {
                __typename: 'Translation',
                code: 1020,
                id: LanguageEnum.SPANISH,
                longName: 'Spanish',
              },
              {
                __typename: 'Translation',
                code: 1022,
                id: LanguageEnum.TAGALOG,
                longName: 'Tagalog',
              },
              {
                __typename: 'Translation',
                code: 1024,
                id: LanguageEnum.TONGAN,
                longName: 'Tongan',
              },
              {
                __typename: 'Translation',
                code: 1026,
                id: LanguageEnum.VIETNAMESE,
                longName: 'Vietnamese',
              },
            ],
          },
        ]
      : []),
  ];
}

function getFiltersFromEntity(entity: Entity): string[] {
  return [entity.id];
}

function getFiltersFromComplex(complex: Complex): string[] {
  return [complex.id];
}

function getFiltersFromComplexArea(complexArea: ComplexArea): string[] {
  const complexAreas = [complexArea.id];
  const complexes = complexArea.complexes.map(complex => complex.id);

  return [...complexAreas, ...complexes];
}

function getFiltersFromDistrict(district: District): string[] {
  const districts = [district.id];
  const complexAreas = district.complexAreas.map((complexArea: any) => complexArea.id);
  const complexes = district.complexAreas.flatMap((complexArea: any) =>
    complexArea.complexes.map((complex: any) => complex.id),
  );

  return [...districts, ...complexAreas, ...complexes];
}

function getFiltersFromTranslationEntity(entity: Entity): string[] {
  switch (entity.id) {
    case LanguageEnum.CEBUANO:
      return ['Entity-1001', 'Entity-1002'];
    case LanguageEnum.CHINESE_SIMPLIFIED:
      return ['Entity-1003', 'Entity-1004'];
    case LanguageEnum.CHINESE_TRADITIONAL:
      return ['Entity-1005', 'Entity-1006'];
    case LanguageEnum.CHUUKESE:
      return ['Entity-1007', 'Entity-1008'];
    case LanguageEnum.ENGLISH:
      return ['Entity-1000', 'Entity-1029'];
    case LanguageEnum.HAWAIIAN:
      return ['Entity-1009', 'Entity-1010'];
    case LanguageEnum.ILOCANO:
      return ['Entity-1011', 'Entity-1012'];
    case LanguageEnum.JAPANESE:
      return ['Entity-1013', 'Entity-1014'];
    case LanguageEnum.KOREAN:
      return ['Entity-1015', 'Entity-1016'];
    case LanguageEnum.MARSHALLESE:
      return ['Entity-1017', 'Entity-1018'];
    case LanguageEnum.SAMOAN:
      return ['Entity-1019', 'Entity-1020'];
    case LanguageEnum.SPANISH:
      return ['Entity-1021', 'Entity-1022'];
    case LanguageEnum.TAGALOG:
      return ['Entity-1023', 'Entity-1024'];
    case LanguageEnum.TONGAN:
      return ['Entity-1025', 'Entity-1026'];
    case LanguageEnum.VIETNAMESE:
      return ['Entity-1027', 'Entity-1028'];
    default:
      return [];
  }
}

interface Props extends BoxProps {
  value?: Entity;
  bucketItem?: BucketItem;
  onSelectOption: (val: EntityFilters) => void;
  slug?: string;
  year?: number;
}

/* The Component */
const EntityFilterMenu: React.FC<Props> = props => {
  const { value, onSelectOption, slug, year, ...otherProps } = props;
  const { data, error, loading } = useQuery(GET_ENTITIES);

  const groupOptions = data ? mapDistrictsToOptions(data.getDistricts, slug, year) : [];

  const setFilters = (selected: any): void => {
    let allowIds;
    if (!selected) {
      return onSelectOption({ selected: undefined });
    }
    switch (selected.__typename) {
      case 'Entity':
        allowIds = getFiltersFromEntity(selected as Entity);
        break;
      case 'District':
        allowIds = getFiltersFromDistrict(selected as District);
        break;
      case 'ComplexArea':
        allowIds = getFiltersFromComplexArea(selected as ComplexArea);
        break;
      case 'Complex':
        allowIds = getFiltersFromComplex(selected as Complex);
        break;
      case 'Translation':
        allowIds = getFiltersFromTranslationEntity(selected as Entity);
        break;
      default:
        break;
    }

    return onSelectOption({ selected, allowIds });
  };

  return (
    <SelectMenu
      value={value}
      options={groupOptions}
      onSelectOption={setFilters}
      isClearable={true}
      label="Filter by"
      placeholder="Select a Filter (optional)"
      optionLabelKey="longName"
      optionValueKey="code"
      isLoading={loading}
      {...otherProps}
    />
  );
};

export default EntityFilterMenu;
