import React, { ReactElement } from 'react';
import { PageContainer, CenteredPageMaxWidthContainer, FullWidthBackgroundTitle } from 'components/Layout';
import ReportGrid from 'components/ReportsGrid';
import useReportsList from 'hooks/useReportsListQuery';

import Aria from 'components/Aria';

const ReportsListPage = (): ReactElement => {
  const { reports, loading } = useReportsList();

  return (
    <PageContainer>
      <FullWidthBackgroundTitle title="Reports" subtitle="Find out how Hawaii Public Schools are Performing" />
      <Aria.ContentAnchor />
      <CenteredPageMaxWidthContainer paddingY="2em">
        <ReportGrid reports={reports} loading={loading} />
      </CenteredPageMaxWidthContainer>
    </PageContainer>
  );
};

export default ReportsListPage;
