import React from 'react';
import { PageContainer } from 'components/Layout';
import { Text, Flex, Box } from '@chakra-ui/core';
import { useCurrentUser } from 'contexts/currentUser';
import useReportsList from 'hooks/useReportsListQuery';
import { Report } from 'typings';
import { BeatLoader } from 'react-spinners';
import ClickableLink from 'components/ClickableLink';

/* The Component */
const AdminPage: React.FC = () => {
  const { reports, loading } = useReportsList();
  const currentUser = useCurrentUser();

  return (
    <PageContainer>
      <Flex justifyContent="space-between">
        <Text fontSize="desktop.h2">Admin Dashboard</Text>
        <Text fontSize="desktop.h4" float="right">
          Welcome Back, {currentUser?.firstName}
        </Text>
      </Flex>
      <Flex>
        <Flex flex="1" flexDir="column" margin=".5rem">
          <Text fontSize="desktop.h3">Manage Reports</Text>
          {reports && reports.map(report => <ReportTab report={report} key={report.slug} />)}
          {loading && (
            <Box margin="125px auto">
              <BeatLoader color="pink" size={30} />
            </Box>
          )}
        </Flex>
        <Flex flex="1" flexDir="column" margin=".5rem">
          {/* TODO: implement other column: <Text fontSize="desktop.h3">Management Others</Text>
          <Grid templateColumns="repeat(2, 1fr)" gap="4">
            <Box marginY=".5rem" padding="1rem" background="white" borderRadius="8px" shadow="light" height="125px">
              <Text fontWeight="bold" color="primary">
                Manage Users
              </Text>
            </Box>
             <Box marginY=".5rem" padding="1rem" background="white" borderRadius="8px" shadow="light">
              <Text fontWeight="bold" color="primary">
                Manage Users
              </Text>
            </Box>
          </Grid> */}
        </Flex>
      </Flex>
    </PageContainer>
  );
};

const ReportTab: React.FC<{ report: Report }> = ({ report }) => {
  return (
    <Box marginY=".5rem" padding="1rem" background="white" borderRadius="8px" shadow="light">
      <ClickableLink to={`/admin/manage-report/${report.slug}`} text={report.name} fontWeight="bold" color="primary" />
    </Box>
  );
};

export default AdminPage;
