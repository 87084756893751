import React, { ReactElement, useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from 'react-apollo';
import { Text, Box, Input, Flex, Textarea, PseudoBox } from '@chakra-ui/core';
import get from 'lodash.get';
import { PageContainer } from 'components/Layout';
import { Formik, Form, Field } from 'formik';
import { PrimaryButton, CancelButton, InvertedPrimaryButton } from 'components/Buttons';
import { useParams } from 'react-router-dom';
import useReport, { REPORT_QUERY } from 'hooks/useReportQuery';
import { Report, AvailableYear } from 'typings';
import { BeatLoader } from 'react-spinners';
import { CloseSVG, PlusSVG } from 'components/Icons';
import OverlayLoader from 'components/OverlayLoader';

const UPDATE_REPORT_MUTATION = gql`
  mutation updateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      name
      slug
    }
  }
`;

const ADD_REPORT_YEAR = gql`
  mutation addReportYear($input: AddReportYearInput!) {
    addReportYear(input: $input) {
      year
      range
    }
  }
`;

const REMOVE_REPORT_YEAR = gql`
  mutation removeReportYear($input: RemoveReportYearInput!) {
    removeReportYear(input: $input) {
      removedYear
    }
  }
`;

/* The Component */
const ManageReport: React.FC = (): ReactElement => {
  const { reportSlug = '' } = useParams();
  const { report, setReport, loading } = useReport(reportSlug);
  const [updateReport, { error, loading: updating }] = useMutation(UPDATE_REPORT_MUTATION);
  const [addValue, setAddValue] = useState('');
  const [addReportYear, { loading: adding }] = useMutation(ADD_REPORT_YEAR, {
    update(cache, { data }: any) {
      const { getReport }: any = cache.readQuery({ query: REPORT_QUERY, variables: { slug: report.slug } });

      const { availableYears, ...otherReportInfo } = getReport;
      if (availableYears) {
        const newYears = [data.addReportYear, ...availableYears];

        newYears.sort((a: AvailableYear, b: AvailableYear) => (a.year < b.year ? 1 : -1));
        cache.writeQuery({
          query: REPORT_QUERY,
          data: { getReport: { ...otherReportInfo, availableYears: newYears } },
        });
      }
    },
  });
  const [removeReportYear, { loading: removing }] = useMutation(REMOVE_REPORT_YEAR, {
    update(cache, { data }: any) {
      const { getReport }: any = cache.readQuery({ query: REPORT_QUERY, variables: { slug: report.slug } });

      const { availableYears, ...otherReportInfo } = getReport;
      if (availableYears) {
        const years = availableYears.filter((ay: AvailableYear) => ay.year !== data?.removeReportYear?.removedYear);
        cache.writeQuery({ query: REPORT_QUERY, data: { getReport: { ...otherReportInfo, availableYears: years } } });
      }
    },
  });

  // Submit for Update Report Info
  const onSubmit = (changes: EdittableReportValues): void => {
    const input = {
      slug: report.slug,
      changes,
    };

    updateReport({ variables: { input } }).then(result => {
      const name = get(result, 'data.updateReport.name');
      setReport((prev: Report) => ({ ...prev, name }));
    });
  };

  const addAvailableYear = (year: number): void => {
    const input = {
      slug: report.slug,
      year,
    };

    addReportYear({ variables: { input } }).then(() => {});
  };

  const removeAvailableYear = (year: number): void => {
    const input = {
      slug: report.slug,
      year,
    };

    removeReportYear({ variables: { input } }).then(() => {});
  };

  return (
    <PageContainer>
      {/* Page Title */}
      <Text fontSize="desktop.h3" fontWeight="bold" color="primary" marginBottom="1rem">
        {report && `MANAGE: ${report.name}`}
      </Text>
      {/* Block 1 - Available Years */}
      <Flex alignItems="center" justifyContent="center" position="relative" marginY="1rem">
        {(adding || removing) && <OverlayLoader />}
        <Box flex="1" background="white" padding="2rem" borderRadius="8px" shadow="light" minHeight="200px">
          <Flex alignItems="center" marginBottom="3rem" justifyContent="space-between" flexWrap="wrap">
            <Box marginRight="2rem" width={'60%'}>
              <Text fontSize="desktop.h3" fontWeight="bold">
                Available Years:
              </Text>
              <Text>
                Add the next year by clicking it&apos;s plus. By adding a year you make it publicly visible on the
                report&apos;s page dropdown and make its PDF reports visible.
              </Text>
            </Box>
            <Box>
              <Text>manual add:</Text>
              <Flex>
                <Box>
                  <Input value={addValue} onChange={(e: any) => setAddValue(e.target.value)} type="number" />
                </Box>
                <Box>
                  <InvertedPrimaryButton
                    onClick={() => {
                      addAvailableYear(parseInt(addValue, 10));
                      setAddValue('');
                    }}
                    height="100%"
                    justifyContent="space-between"
                  >
                    <PlusSVG />
                  </InvertedPrimaryButton>
                </Box>
              </Flex>
            </Box>
          </Flex>
          {report && (
            <UpdateAvailableYears
              availableYears={report.availableYears}
              onAdd={addAvailableYear}
              onRemove={removeAvailableYear}
            />
          )}
          {(loading || updating) && (
            <Flex
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              zIndex={100}
              background="rgba(255,255,255,.8)"
            >
              <BeatLoader color="pink" size="40" />
            </Flex>
          )}
        </Box>
      </Flex>
      {/* Block 2 - Update Report Info */}
      <Flex alignItems="center" justifyContent="center" minHeight="inherit" position="relative" marginY="1rem">
        <Box flex="1" background="white" padding="2rem" borderRadius="8px" shadow="light" minHeight="300px">
          <Text fontSize="desktop.h3" fontWeight="bold">
            Report Info:
          </Text>
          <Text marginBottom="1.5rem">
            Update any report information and then click save. Optionally, click cancel to undo all un-saved changes.
            Changing pages will also cancel.
          </Text>
          {report && <UpdateReportForm report={report} onSubmit={onSubmit} />}
          {(loading || updating) && <OverlayLoader />}
        </Box>
      </Flex>
    </PageContainer>
  );
};

interface UpdateAvailableYearsProps {
  availableYears: AvailableYear[];
  onAdd: any;
  onRemove: (year: number) => any;
}

const UpdateAvailableYears: React.FC<UpdateAvailableYearsProps> = ({ availableYears, onRemove, onAdd }) => {
  const nextYear: any = { year: availableYears[0].year + 1 };

  return (
    <Flex wrap="wrap">
      <YearPill year={nextYear} onAdd={onAdd} />
      {availableYears.map(year => (
        <YearPill year={year} onRemove={onRemove} key={year.year} />
      ))}
    </Flex>
  );
};

const YearPill: React.FC<{ year: AvailableYear; onRemove?: any; onAdd?: any }> = ({
  year,
  onRemove,
  onAdd,
}): ReactElement => {
  const style: any = onRemove
    ? { backgroundColor: 'primary', color: 'white' }
    : { backgroundColor: 'white', color: 'primary', borderWidth: '1px' };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      {...style}
      borderRadius="10rem"
      key={year.year}
      paddingX="1rem"
      paddingY=".5rem"
      margin=".25rem"
    >
      <Text color={style.color}>{year.year}</Text>
      {onAdd && (
        <Text onClick={() => onAdd(year.year)} color={style.color} marginLeft=".5rem">
          <PseudoBox
            as={pbProps => (
              <Box {...pbProps}>
                <PlusSVG width={24} height={24} color={style.color} />
              </Box>
            )}
            _hover={{ cursor: 'pointer' }}
          />
        </Text>
      )}
      {onRemove && (
        <Text onClick={() => onRemove(year.year)} color={style.color} marginLeft=".5rem">
          <PseudoBox
            as={pbProps => (
              <Box {...pbProps}>
                <CloseSVG width={24} height={24} />
              </Box>
            )}
            _hover={{ cursor: 'pointer' }}
          />
        </Text>
      )}
    </Flex>
  );
};

interface EdittableReportValues {
  name: string;
  type: string;
  slug: string;
  description: string;
  lastUpdated: string;
}

interface UpdateReportFormProps {
  report: Report;
  onSubmit: (values: EdittableReportValues) => any;
}
const UpdateReportForm: React.FC<UpdateReportFormProps> = ({ report, onSubmit }) => {
  // pull off the edittable values off the report object:
  const initialValues: EdittableReportValues = {
    name: report.name,
    type: report.type,
    slug: report.slug,
    description: report.description,
    lastUpdated: report.lastUpdated,
  };

  const updateReport = async (edittableReportValues: EdittableReportValues): Promise<any> => {
    onSubmit(edittableReportValues);
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={updateReport}
        render={fk => (
          <Form>
            <Field
              name="name"
              render={({ field, form, meta }: any): ReactElement => (
                <Box marginY="1rem">
                  <Text marginY=".5rem" fontWeight="bold">
                    name:
                  </Text>
                  <Input type="text" {...field} borderColor="hsl(0,0%,80%)" color="black" />
                  {meta.touched && meta.error}
                </Box>
              )}
            />
            <Field
              name="slug"
              render={({ field, form, meta }: any): ReactElement => (
                <Box marginY="1rem">
                  <Text marginY=".5rem" fontWeight="bold" display="inline">
                    report-slug:
                  </Text>
                  <Text marginY=".5rem" color="gray.800" display="inline">
                    &nbsp; (must be unique)
                  </Text>
                  <Input type="text" {...field} borderColor="hsl(0,0%,80%)" color="black" />
                  {meta.touched && meta.error}
                </Box>
              )}
            />
            <Field
              name="description"
              render={({ field, form, meta }: any): ReactElement => (
                <Box marginY="1rem">
                  <Text marginY=".5rem" fontWeight="bold">
                    description:
                  </Text>
                  <Textarea type="text" {...field} borderColor="hsl(0,0%,80%)" color="black" height="150px" />
                  {meta.touched && meta.error}
                </Box>
              )}
            />
            <Flex justifyContent="flex-end" marginTop="2rem">
              <CancelButton marginRight=".5rem" onClick={fk.handleReset}>
                Cancel
              </CancelButton>
              <PrimaryButton type="submit">Update Report</PrimaryButton>
            </Flex>
          </Form>
        )}
      />
    </Box>
  );
};

export default ManageReport;
