import { Text, Flex, Box, PseudoBox } from '@chakra-ui/core';
import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ClickableLink from 'components/ClickableLink';
import navListItems from './NavListItems';
import * as Types from './types';

import ChevronToggle from '../Icons/ChevronToggle';
import NavSubMenu from './NavSubMenu';

/* Render The List of Navigation Menu Items */
const NavList: React.FC = (): ReactElement => {
  return (
    <>
      {navListItems.map((navItem: Types.NavItem) => (
        <NavItem key={navItem.navPath} {...navItem} />
      ))}
    </>
  );
};

/**
 * The Container and State for each individual nav item and its sub menu
 * renders a sub menu if the sections array is present.
 */
const NavItem: React.FC<Types.NavItem> = ({ navTitle, navPath, sections, isExternal }): ReactElement => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>();
  const location = useLocation();
  const { pathname } = location;

  /* close menu on location change */
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  /* close menu on click within */
  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      const node = menuRef.current;
      if (node && !node.contains(e.target as Node)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClick, false);

    return (): void => document.removeEventListener('mousedown', handleClick, false);
  }, [menuRef]);

  let isActive: boolean;
  if (navPath === '/') {
    isActive = pathname === navPath;
  } else {
    isActive = pathname.includes(navPath) || menuOpen;
  }

  return (
    <Box ref={menuRef} height="100%">
      <Box height="100%">
        <PseudoBox
          as={pbProps => (
            <Flex
              height="100%"
              align="center"
              justify="center"
              borderTop={'3px solid'}
              borderTopColor={isActive ? 'white' : 'secondary'}
              padding="0px 16px 0px 16px"
              {...pbProps}
            >
              <ClickableLink active={isActive} to={navPath} external={isExternal} color={'white'} hoverColor={'white'}>
                <Text textAlign="center">{navTitle}</Text>
              </ClickableLink>
              {sections && <ChevronToggle open={menuOpen} onClick={setMenuOpen} color="white" />}
            </Flex>
          )}
          _hover={{ borderTop: '3px solid', borderTopColor: 'white' }}
        />
      </Box>
      {sections && menuOpen && (
        <Box position="absolute" top="56px" zIndex={10} backgroundColor="secondary">
          <NavSubMenu sections={sections} rootPath={navPath} />
        </Box>
      )}
    </Box>
  );
};

export default NavList;
