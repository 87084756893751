import React from 'react';
import { Box } from '@chakra-ui/core';

import { ChevronDownSVG, ChevronUpSVG } from '.';

/* TS Props Typing */
interface Props {
  open: boolean;
  onClick: (t: boolean) => void;
  color?: string;
}

/* The Component */
const ChevronToggle: React.FC<Props> = ({ open, onClick, color = 'gray.900' }) => {
  return (
    <Box className="hoverPointer" onClick={(): void => onClick(!open)}>
      {open ? <ChevronUpSVG color={color} /> : <ChevronDownSVG color={color} />}
    </Box>
  );
};

export default ChevronToggle;
