import React, { ReactElement, ReactNode } from 'react';
import { ApolloClient, HttpLink, from } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { ApolloProvider } from 'react-apollo';
import PropTypes from 'prop-types';
import { typeDefs, resolvers } from 'Apollo/clientSchema';
import cache from './InMemoryCache';

import { graphqlApiUri } from '../config';

const API_LINK = new HttpLink({
  uri: graphqlApiUri,
  headers: {
    Authorization: `Bearer `,
  },
});

const ERROR_LINK = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const link = from([ERROR_LINK, API_LINK]);

const client = new ApolloClient({
  link,
  cache,
  typeDefs,
  resolvers,
});

/* TS Props Typing */
interface Props {
  children: ReactNode;
}

/* The Component */
const ApolloClientProvider: React.FC<Props> = ({ children }): ReactElement => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

/* Runtime React propTypes */
ApolloClientProvider.propTypes = {
  children: PropTypes.node,
};

export default ApolloClientProvider;
