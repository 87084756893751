import React from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/core';
import { useIsThemeBreakPoint } from 'contexts/Breakpoints';
import { SchoolSVG, RightArrowSVG } from 'components/Icons';
import ClickableLink from 'components/ClickableLink';
import ChartIcon from '../chartSVG.svg';

const ContactSection = () => {
  const isLaptop = useIsThemeBreakPoint(1);

  return (
    <Box backgroundColor="secondary" marginTop="60px">
      <Box maxWidth="maxWidth" margin="0 auto">
        <Flex
          justifyContent="space-around"
          alignItems="center"
          flexWrap="wrap"
          paddingX={!isLaptop ? '42px' : ''}
          position="relative"
          top="-60px"
        >
          {/* GO TO SCHOOLS CARD */}
          <Box
            flex="1"
            margin=".5em"
            minWidth="357px"
            backgroundColor="primary"
            borderRadius="16px"
            padding="1.25em"
            height="100%"
          >
            <Box marginBottom=".5em">
              <SchoolSVG color="white" />
            </Box>
            <Box marginLeft="2em">
              <Box display="inline-block">
                <ClickableLink to="/schools" color="white" fontWeight="bold" _hover={{ color: '#eee' }}>
                  <Text>Schools</Text>
                </ClickableLink>
                <Text color="white">Find a report by school</Text>
              </Box>
              <Box display="inline-block" float="right">
                <ClickableLink to="/schools">
                  <RightArrowSVG color="white" />
                </ClickableLink>
              </Box>
            </Box>
          </Box>
          {/* GO TO ADC CARD */}
          <Box
            flex="1"
            margin=".5em"
            minWidth="357px"
            backgroundColor="primary"
            borderRadius="16px"
            padding="1.25em"
            width="45%"
          >
            <Box marginBottom=".5em">
              <Image src={ChartIcon} alt="" />
            </Box>
            <Box marginLeft="2em">
              <Box display="inline-block">
                <ClickableLink to="/adc-redirect" color="white" fontWeight="bold" _hover={{ color: '#eee' }}>
                  <Text>Accountability Data Center</Text>
                </ClickableLink>
                <Text color="white">View data visualizations on school performance</Text>
              </Box>
              <Box display="inline-block" float="right">
                <ClickableLink to="/adc-redirect">
                  <RightArrowSVG color="white" />
                </ClickableLink>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Flex flexDirection="column" alignItems="center" width="80%" margin="0 auto">
          <Text color="white" fontSize="1.25em" marginBottom="1.2em">
            If you would like more information or an accessible version of an existing report, please contact us.
          </Text>
          <ClickableLink to="/contact" marginBottom="3em">
            <Box backgroundColor="primary" borderRadius="44px" width="300px" padding="1em" textAlign="center">
              <Text color="white" fontSize=".9rem">
                Contact Us
              </Text>
            </Box>
          </ClickableLink>
        </Flex>
      </Box>
    </Box>
  );
};

export default ContactSection;
