export function onLoad(map: google.maps.Map): void {
  map.data.loadGeoJson('/assets/data/boundaries/hi-new.json');

  // extracts the style feature from the loaded geoJson.
  map.data.setStyle(feature => {
    let opacity = 0.3;
    if (feature.getProperty('active')) {
      opacity = 0.6;
    }

    return {
      title: feature.getProperty('description'),
      fillColor: feature.getProperty('color'),
      fillOpacity: opacity,
      strokeColor: feature.getProperty('color'),
      strokeWeight: 1,
    };
  });
}

export function highlightAndBoundActiveFeature(complexCode: number, map: google.maps.Map<Element>): void {
  // set the active property for the selected feature (active property sets a darker opacity.), clear it for all others.
  // process points to get bounds to fit map.
  const bounds = new window.google.maps.LatLngBounds();
  map.data.forEach(feature => {
    if (feature.getProperty('value') === complexCode) {
      feature.setProperty('active', true);
      processPoints(feature.getGeometry(), bounds.extend, bounds);
    } else {
      feature.setProperty('active', false);
    }
  });

  // zoom to complex
  map.fitBounds(bounds);
}

export function processPoints(geometry: any, callback: any, thisArg: any): any {
  if (geometry instanceof window.google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof window.google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function(g: any) {
      processPoints(g, callback, thisArg);
    });
  }
}
