import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useIsThemeBreakPoint } from '../../contexts/Breakpoints';

import NavList from './NavList';
import { MenuSVG } from '../Icons';
import DOELogoColor from '../Branding/DOELogoColor';
import SideNavMenu from './SideNavMenu';

/* The Component */
const Header: React.FC = (): ReactElement => {
  const isDesktop = useIsThemeBreakPoint(2);
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  useEffect(() => {
    setSideMenuOpen(false);
  }, [location]);

  return (
    <Box width="100vw" backgroundColor="secondary" pos="fixed" zIndex={1000}>
      <Box maxWidth="1150px" paddingX="1em" margin="0 auto">
        <Flex flexDirection="row" justifyContent="space-between">
          {/* Logo and Brand Text */}
          <Flex flexDirection="row" align="center" margin="4px 0">
            <Link to="/">
              <DOELogoColor width="56px" height="56px" />
            </Link>
            <Box marginLeft="16px">
              <Link to="/">
                <Text fontWeight="bold" fontSize="1.5rem" color="white">
                  ARCH
                </Text>
              </Link>
            </Box>
          </Flex>
          {/* Nav and User Stuff */}
          {isDesktop ? (
            <Flex flexDirection="row" align="center">
              <NavList />
              {/* <UserActionItems /> FIXME: disabled until login is built */}
            </Flex>
          ) : (
            <Button
              leftIcon={MenuSVG}
              onClick={() => setSideMenuOpen(true)}
              color="white"
              border="none"
              variant="link"
              _active={{ color: 'gray.400' }}
              marginLeft="24px"
            >
              {}
            </Button>
          )}
        </Flex>
      </Box>
      {!isDesktop && sideMenuOpen && <SideNavMenu closeMenu={() => setSideMenuOpen(false)} />}
    </Box>
  );
};

// const UserActionItems: React.FC = (): ReactElement => {
//   // const currentUser = null;

//   const goToLogin = () => {};

//   return (
//     <Box marginLeft="32px">
//       <Flex direction="row">
//         <PrimaryButton onClick={goToLogin} width="118px" marginRight="24px">
//           LOGIN
//         </PrimaryButton>
//         <Notifications />
//       </Flex>
//     </Box>
//   );
// };

export default Header;
