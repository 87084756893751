/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { BoxProps } from '@chakra-ui/core';
import get from 'lodash.get';
import { alphabetize } from 'utilityFns/alphabetize';
import { BucketItem } from 'typings';
import SelectMenu from './SelectMenu';

interface Props extends BoxProps {
  value?: number;
  bucketItem?: BucketItem;
  onSelectOption: (val?: BucketItem) => void;
  onLoading: (loading: boolean) => void;
  slug?: string;
  year?: number;
  filters?: string[];
}

const GET_BUCKET_ITEMS = gql`
  query getReport($slug: String!, $year: Int!) {
    getReport(slug: $slug) {
      slug
      bucketItems(year: $year) {
        filePath
        filename
        code
        entity {
          __typename
          id
          code
          name
          longName @client
          ... on School {
            district {
              id
              code
            }
            complex {
              id
              code
            }
          }
        }
      }
    }
  }
`;

type ReportBucketMenuGroups = {
  translations: BucketItem[];
  schools: BucketItem[];
  complexes: BucketItem[];
  complexAreas: BucketItem[];
  state: BucketItem[];
  others: BucketItem[];
};

function mapItemsToGroup(items: BucketItem[]): ReportBucketMenuGroups {
  const groupItems = {
    state: items
      .filter(i => i.code === 999 || i.code === 3001 || i.code === 3002)
      .sort((a, b) => a.code - b.code),
    schools: items
      .filter(i => i.entity?.__typename === 'School')
      .sort((a, b) => alphabetize(a.entity.name, b.entity.name)),
    complexes: items
      .filter(i => i.entity?.__typename === 'Complex')
      .sort((a, b) => alphabetize(a.entity.name, b.entity.name)),
    complexAreas: items
      .filter(i => i.entity?.__typename === 'ComplexArea')
      .sort((a, b) => alphabetize(a.entity.name, b.entity.name)),
    others: items.filter(i => (!i.entity) && ![999, 3001, 3002].includes(i.code)),
    translations: items.filter(i => i.entity?.__typename === 'Entity' && ![999, 3001, 3002].includes(i.code)).sort((a, b) => alphabetize(a.entity.name, b.entity.name)),
  };

  return groupItems;
}

type GroupOptions = Array<{ label: string; options: BucketItem[] }>;
function getGroupOptions(data: any): GroupOptions | undefined {
  let items: BucketItem[] = get(data, 'getReport.bucketItems');

  if (!items) return undefined;

  items = items.map((item, i) => {
    return {
      ...item,
      label: item.entity ? item.entity.longName : item.filename,
      code: item.code ? item.code : i + 1000,
    };
  });
  const { state, schools, complexes, complexAreas, others, translations } = mapItemsToGroup(items);

  const groupOptions = [
    { label: 'State', options: state },
    { label: 'Schools', options: schools },
    { label: 'Complexes', options: complexes },
    { label: 'Complex Areas', options: complexAreas },
    { label: 'Others', options: others },
    { label: 'Translations', options: translations },
  ];
  return groupOptions;
}

function findInGroupOptions(options: GroupOptions, value: number): BucketItem | undefined {
  return options
    .reduce((allOptions: BucketItem[], option) => [...allOptions, ...option.options], [])
    .find(item => item.code === value);
}

/* The Component */
const ReportBucketMenu: React.FC<Props> = props => {
  const { slug, year, value, onSelectOption, onLoading, bucketItem, filters, ...otherProps } = props;
  const { data, error, loading } = useQuery(GET_BUCKET_ITEMS, {
    variables: { slug, year },
    skip: !slug || !year,
  });

  const groupOptions = getGroupOptions(data);

  // filters are given as an array of entity ids (ex: ComplexArea-1, Complex-340);
  // loop over each option set and filter out if its allowed.
  // The array of ids is compiled by walking the tree of the selected filter. so its mixed ids of [district, complexAreas, complexes.];
  // for the School case, if there is no district filter we match it on the complex.id association
  // if there is a district filter, we match it on the complex.id association if complex.id is NOT Complex-777 (Charter School Complex),
  // for Schools within Complex-777 we match it on the district.id association
  if (filters && groupOptions) {
    groupOptions.forEach(group => {
      if (group.label === 'Schools') {
        const hasDistrictFilter = filters.findIndex(f => f.includes('District')) > -1;
        group.options = group.options.filter(({ entity }: { entity: any }) => {
          if (hasDistrictFilter) {
            return (
              (entity.complex.id !== 'Complex-777' && filters.includes(entity.complex.id)) ||
              filters.includes(entity.district.id)
            );
          }
          return filters.includes(entity.complex.id);
        });
        return;
      }

      group.options = group.options.filter(option => option.entity && filters.includes(option.entity.id));
    });
  }

  // TODO: Removed Special Item -> wont be able to complete on budget.
  // Push special items onto the options list.
  // if (groupOptions) {
  //   groupOptions.push({
  //     label: 'Bulk Download',
  //     options: [
  //       {
  //         type: 'BULK_DOWNLOAD',
  //         label: 'Bulk download all of these',
  //         code: 10001,
  //         entity: {
  //           code: 10001,
  //           __typename: '',
  //           name: '',
  //           id: '',
  //           longName: '',
  //         },
  //         filePath: '',
  //         filename: '',
  //       },
  //     ],
  //   });
  // }

  /** Propagate this loading state up. */
  useEffect(() => {
    onLoading(loading);
  }, [loading, onLoading]);

  /* handle the case that groupOptions change and we want to select the new bucketItem based on the value.
   */
  useEffect(() => {
    if (groupOptions && value) {
      const selected = findInGroupOptions(groupOptions, value);
      onSelectOption(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /** If value is not in GroupOptions: set selected to undefined. */
  useEffect(() => {
    if (loading) return;

    if (groupOptions && value) {
      const selected = findInGroupOptions(groupOptions, value);
      if (!selected) {
        onSelectOption(undefined);
      }
    }
  }, [groupOptions, data, loading, value, onSelectOption]);

  /* handle the case that a value is provided, but the store DOES NOT have a bucketItem.
    -if !bucketItem but a value, we try to get the bucketItem in options, and force onSelectOption to be called with that value.
  */
  useEffect(() => {
    if (!bucketItem && groupOptions && value) {
      const selected = findInGroupOptions(groupOptions, value);
      if (selected) {
        onSelectOption(selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, year]);

  const isDisabled = !slug || !year;
  return (
    <SelectMenu
      value={value && groupOptions && findInGroupOptions(groupOptions, value)}
      options={groupOptions || []}
      onSelectOption={onSelectOption}
      label="For"
      placeholder={isDisabled ? '' : 'Make a Selection'}
      optionLabelKey="label"
      optionValueKey="code"
      isDisabled={isDisabled}
      isLoading={loading}
      {...otherProps}
    />
  );
};

export default ReportBucketMenu;
