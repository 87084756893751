import React, { ReactElement } from 'react';
import { Report, Year, BucketItem } from 'typings';
import {
  useReportSearchState,
  useReportSearchDispatch,
  ReducerActions,
  ReportSearchState,
} from 'components/Forms/ReportSearchProvider';
import { ButtonProps, Button } from '@chakra-ui/core';
import { useIsThemeBreakPoint } from '../../contexts/Breakpoints';

import ReportListMenu from '../Menus/ReportListMenu';
import AvailableYearsMenu from '../Menus/AvailableYearsMenu';
import ReportBucketMenu from '../Menus/ReportBucketMenu';
import { PrimaryButton } from '../Buttons';

export type HomeReportSearchFormOnSubmitFn = (state: ReportSearchState) => void;

interface HomeReportSearchFormProps {
  onSubmit: HomeReportSearchFormOnSubmitFn;
}

const HomeReportSearchForm: React.FC<HomeReportSearchFormProps> = ({ onSubmit }): ReactElement => {
  const laptop = useIsThemeBreakPoint(1);
  const { report, year, entity, bucketItem, entityFilters } = useReportSearchState();
  const dispatch = useReportSearchDispatch();

  const showReportBucketMenu = report !== 'hidoe-data-book';
  const isSearchDisabled = !bucketItem && report !== 'hidoe-data-book';

  const restartWithReport = React.useCallback(
    (slug: string) => dispatch({ type: ReducerActions.RESET, payload: { report: slug } }),
    [dispatch],
  );

  const updateFieldOnState = React.useCallback(
    (fieldName, payload) => dispatch({ type: ReducerActions.FIELD, fieldName, payload }),
    [dispatch],
  );

  const updateYearOnState = React.useCallback(payload => dispatch({ type: ReducerActions.YEAR_UPDATE, payload }), [
    dispatch,
  ]);

  const updateBucketItemOnState = React.useCallback(
    payload => dispatch({ type: ReducerActions.BUCKET_ITEM_SELECTED, payload }),
    [dispatch],
  );

  return (
    <>
      <ReportListMenu
        value={report}
        onSelectOption={(selected: Report): void => restartWithReport(selected.slug)}
        paddingTop=".5rem"
      />
      <AvailableYearsMenu
        slug={report}
        value={year}
        onSelectOption={(selected: Year): void => updateYearOnState(selected.year)}
        paddingTop=".5rem"
      />
      {showReportBucketMenu && (
        <ReportBucketMenu
          slug={report}
          year={year}
          value={entity}
          bucketItem={bucketItem}
          filters={entityFilters && entityFilters.allowIds}
          onSelectOption={(selected?: BucketItem): void =>
            updateBucketItemOnState({ entity: selected?.code, bucketItem: selected })
          }
          onLoading={bucketLoading => updateFieldOnState('bucketLoading', bucketLoading)}
          paddingTop=".5rem"
        />
      )}
      <SearchButton
        isDisabled={isSearchDisabled}
        onClick={(): void =>
          onSubmit({
            report,
            year,
            entity,
            bucketItem,
          })
        }
        width="100%"
        marginTop="1rem"
      >
        SEARCH
      </SearchButton>
    </>
  );
};

export const SearchButton: React.FC<ButtonProps> = props => {
  const { onClick, children, isDisabled, title, ...buttonProps } = props;
  return (
    <Button
      title={isDisabled ? 'You must complete the form.' : 'Search for the selected report.'}
      isDisabled={isDisabled}
      height="50px"
      backgroundColor="secondary"
      borderRadius="44px"
      color={'white'}
      onClick={onClick}
      _disabled={{ color: '#000', backgroundColor: '#ddd' }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default HomeReportSearchForm;
