import { gql, ApolloError } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useMakeToast } from 'components/Toaster/Toaster';
import { Report } from '../typings';

const GET_REPORTS_LIST_QUERY = gql`
  query getReportsList {
    getReportsList {
      type
      name
      slug
      description
      current
      lastUpdated
      defaultYear {
        year
      }
      availableYears {
        year
      }
    }
  }
`;

const useReportsList = (): { reports: Report[]; error: ApolloError | undefined; loading: boolean } => {
  const { data, error, loading } = useQuery(GET_REPORTS_LIST_QUERY);
  const makeToast = useMakeToast();
  if (error) {
    makeToast({
      status: 'danger',
      text: 'Use Reports List Error',
      subText: 'there was an issue getting the list of available reports.',
    });
  }

  const reports = data && data.getReportsList;

  return { reports, error, loading };
};

export default useReportsList;
