import React from 'react';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';

import Toaster from 'components/Toaster/Toaster';
import ARCHClientProvider from './Apollo/ARCHClientProvider';
import { CurrentUserProvider } from './contexts/currentUser';

import theme from './theme/theme';
import GlobalStyles from './theme/GlobalStyles';

import Router from './Router';
import BreakpointsProvider from './contexts/Breakpoints';

const App: React.FC = () => {
  return (
    <ARCHClientProvider>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <BreakpointsProvider>
          <GlobalStyles />
          <Toaster>
            <CurrentUserProvider>
              <Router />
            </CurrentUserProvider>
          </Toaster>
        </BreakpointsProvider>
      </ThemeProvider>
    </ARCHClientProvider>
  );
};

export default App;
