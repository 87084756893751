import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { BoxProps } from '@chakra-ui/core';
import get from 'lodash.get';
import SelectMenu from './SelectMenu';
import { Year } from '../../typings';

interface Props extends BoxProps {
  value?: number;
  onSelectOption: (val: Year) => void;
  slug?: string;
}

const GET_REPORT_YEARS = gql`
  query getReport($slug: String!) {
    getReport(slug: $slug) {
      slug
      availableYears {
        year
        range
      }
    }
  }
`;

/* The Component */
const AvailableYearsMenu: React.FC<Props> = props => {
  const { slug, value, onSelectOption, ...otherProps } = props;
  const { data } = useQuery(GET_REPORT_YEARS, { variables: { slug }, skip: !slug, fetchPolicy: 'cache-and-network' });

  const reportYears = get(data, 'getReport.availableYears');

  function getValueInData(): Year | undefined | null {
    if (value && reportYears) {
      return reportYears.find((year: Year) => year.year === value);
    }
    return null;
  }

  const isDisabled = !slug;
  return (
    <SelectMenu
      value={getValueInData()}
      options={reportYears}
      onSelectOption={onSelectOption}
      autoSelectFirstOption={true}
      label="School Year"
      placeholder={isDisabled ? '' : 'Select a School Year'}
      optionLabelKey="range"
      optionValueKey="year"
      isDisabled={isDisabled}
      {...otherProps}
    />
  );
};

export default AvailableYearsMenu;
