import React, { ReactElement } from 'react';
import { Box, Text, ListIcon, Flex } from '@chakra-ui/core';
import ClickableLink from 'components/ClickableLink';
import { PageContainer, CenteredPageMaxWidthContainer, FullWidthBackgroundTitle } from 'components/Layout';
import { MailSVG, ClockSVG, PhoneSVG, FaxSVG, HomeOutlineSVG } from '../components/Icons';

/* The Component */
const ContactPage: React.FC = (): ReactElement => {
  return (
    <PageContainer backgroundColor="secondary">
      <FullWidthBackgroundTitle title="Contact Us" />
      <CenteredPageMaxWidthContainer paddingY="5em">
        <Box background="white" borderRadius=".5em" shadow="light" padding="2em" maxWidth="50em" margin="0 auto">
          <Text color="gray.900" marginTop="28px">
            ARCH is maintained by the Accountability Section of the Hawaii Department of Education, Office of Strategy,
            Innovation and Performance. The Accountability Section:
          </Text>
          <br />
          <Box marginLeft="16px">
            <Flex color="gray.900">
              <Box>
                <ListIcon icon="check-circle" color="primary" />
              </Box>
              <Box>Develops and implements school accountability systems and reporting</Box>
            </Flex>
            <Flex color="gray.900" marginTop="8px">
              <Box>
                <ListIcon icon="check-circle" color="primary" />
              </Box>
              <Box>Provides student, school, complex area, and state data for performance and improvement efforts</Box>
            </Flex>
            <Flex color="gray.900" marginTop="8px">
              <Box>
                <ListIcon icon="check-circle" color="primary" />
              </Box>
              <Box>Ensures compliance with federal and state school accountability requirements</Box>
            </Flex>
            <Flex color="gray.900" marginTop="8px">
              <Box>
                <ListIcon icon="check-circle" color="primary" />
              </Box>
              <Box>
                Supports schools and complex areas in understanding and using a variety of accountability and school
                quality data that are intended to support improvement across all levels of the education system
              </Box>
            </Flex>
          </Box>
          <br />
          <br />
          <Text color="gray.900">For more information about ARCH, please contact us.</Text>
          <Box display="inline-block" marginTop="22px" title="open in mail">
            <ClickableLink to="mailto:OSIP-Arch@k12.hi.us" external>
              <Box display="inline-block">
                <MailSVG color="primary" />
              </Box>
              <Text marginLeft="20px" color="gray.900" display="inline-block" verticalAlign="top">
                OSIP-Arch@k12.hi.us
              </Text>
            </ClickableLink>
          </Box>
          <Flex marginTop="22px">
            <HomeOutlineSVG color="primary" />
            <Box>
              <Text marginLeft="20px" color="gray.900">
                State of Hawaii Department of Education
              </Text>
              <Text marginLeft="20px" color="gray.900">
                Office of Strategy, Innovation and Performance
              </Text>
              <Text marginLeft="20px" color="gray.900">
                Accountability Section
              </Text>
              <Text marginLeft="20px" color="gray.900">
                637 18th Avenue, Room C202
              </Text>
              <Text marginLeft="20px" color="gray.900">
                Honolulu, HI 96816-4444
              </Text>
            </Box>
          </Flex>
          <Flex align="center" marginTop="22px">
            <ClockSVG color="primary" />
            <Text marginLeft="20px" color="gray.900">
              Monday through Friday, 7:45 a.m. - 4:30 p.m. HST
            </Text>
          </Flex>
          <Flex align="center" marginTop="22px">
            <PhoneSVG color="primary" />
            <Text marginLeft="20px" color="gray.900">
              (808) 307-3650
            </Text>
          </Flex>
          <Flex align="center" marginTop="22px">
            <FaxSVG color="primary" />
            <Text marginLeft="20px" color="gray.900">
              (808) 735-8260
            </Text>
          </Flex>
        </Box>
      </CenteredPageMaxWidthContainer>
    </PageContainer>
  );
};

/* Runtime React propTypes */
ContactPage.propTypes = {};

export default ContactPage;
