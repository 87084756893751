import React, { ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/core';

type ARCHError = { message: string };

/* The Component */
export const BasicError: React.FC<{ error?: ARCHError }> = ({ error }): ReactElement => {
  if (!error) {
    return (
      <Box>
        <Text>&nbsp;</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text color="status.danger">{error.message}</Text>
    </Box>
  );
};
