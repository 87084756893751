import React, { ReactElement, useEffect, useState } from 'react';
import { Year } from 'typings';
import AvailableYearsMenu from 'components/Menus/AvailableYearsMenu';
import { Box, Text, Flex, Image } from '@chakra-ui/core';
import useReport from 'hooks/useReportQuery';
import ClickableLink from 'components/ClickableLink';
import {
  PageContainer,
  FullWidthBackgroundTitle,
  CenteredPageMaxWidthContainer,
  ContactFooter,
} from 'components/Layout';
import ResearchDocumentPNG from 'assets/images/researchDocumentLarge.png';
import { useQueryStringContext } from '../../contexts/queryStringContext';
import DataBookInfoBlock from './DataBookInfoBlock';

interface DataBookPageProps {
  slug: string;
}
const DataBookPage: React.FC<DataBookPageProps> = ({ slug }): ReactElement => {
  const { report } = useReport(slug);
  const [selectedYear, setSelectedYear] = useState<number | undefined>();

  const qs = useQueryStringContext();

  useEffect(() => {
    setSelectedYear(qs.year);
  }, [qs]);

  return (
    <PageContainer>
      <FullWidthBackgroundTitle title={report ? report.name : ''} />
      <Box backgroundColor="accent" className="whiteLabels" paddingY="1em">
        <CenteredPageMaxWidthContainer>
          <Box maxWidth="300px" marginBottom="16px">
            <AvailableYearsMenu
              slug={slug}
              onSelectOption={(selected: Year) => setSelectedYear(selected.year)}
              value={selectedYear}
            />
          </Box>
        </CenteredPageMaxWidthContainer>
      </Box>
      <CenteredPageMaxWidthContainer marginTop="2em">
        {!selectedYear ? (
          <Flex margin="82px" direction="column" alignItems="center" minHeight="300px">
            <Image src={ResearchDocumentPNG} width="100px" />
            <Text textAlign="center" color="gray.900" marginTop="32px" minWidth="240px">
              Select a school year from the dropdown menu to get the related DataBook information.
            </Text>
          </Flex>
        ) : (
          <Box backgroundColor="white" borderRadius="8px" shadow="light">
            <DataBookInfoBlock year={selectedYear} slug={slug} />
          </Box>
        )}
      </CenteredPageMaxWidthContainer>
      <Box backgroundColor="#F9FBFF" paddingY="4em">
        {report && (
          <CenteredPageMaxWidthContainer>
            <Text color="textGray" lineHeight="26px">
              {report.description}
            </Text>
            {report.externalLink && (
              <Text as="p" color="textGray" marginTop="1.5em">
                For more information go to:{' '}
                <ClickableLink
                  to={report.externalLink}
                  external
                  text={report.externalLink}
                  fontWeight="bold"
                  color="primary"
                />
              </Text>
            )}
            {report.lastUpdated && (
              <Text color="textGray" marginTop="1.5em">
                <strong>Last Updated: </strong>
                {report.lastUpdated}
              </Text>
            )}
          </CenteredPageMaxWidthContainer>
        )}
      </Box>
      <ContactFooter />
    </PageContainer>
  );
};

export default DataBookPage;
