import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { Box, Flex, PseudoBox, Button } from '@chakra-ui/core';
import { useLocation, Link } from 'react-router-dom';
import Notifications from './Notifications';

import { CloseSVG } from '../Icons';
import ChevronToggle from '../Icons/ChevronToggle';

import navListItems from './NavListItems';
import * as Types from './types';
import NavSubMenu from './NavSubMenu';

const useIsActiveLocation = (navPath: string): boolean => {
  const { pathname } = useLocation();

  // handle the home case
  if (navPath === '/') {
    return pathname === navPath;
  }

  // check if the pathname includes this nav path.
  return pathname.includes(navPath);
};

interface SideMenuProps {
  closeMenu: () => void;
}
/* The Component */
const SideNavMenu: React.FC<SideMenuProps> = ({ closeMenu }) => {
  const menuRef = useRef<HTMLDivElement>();

  /* close menu when inside link is clicked */
  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      const node = menuRef.current;
      if (node && !node.contains(e.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClick, false);

    return (): void => document.removeEventListener('mousedown', handleClick, false);
  }, [closeMenu, menuRef]);

  return (
    <Box position="absolute" top="0" right="0" width="100vw" height="100vh" backgroundColor="rgba(0,0,0,.4)">
      <Box ref={menuRef} width="225px" height="100%" backgroundColor="secondary" position="absolute" right="0">
        <Flex justify="flex-end" padding="20px">
          {/* <Notifications iconColor="white" /> */}
          <Box className="hoverPointer" onClick={closeMenu}>
            <Box as={(): ReactElement => <CloseSVG color="white" width={36} height={36} />} />
          </Box>
        </Flex>
        <Box overflow="scroll" height="calc(100vh - 89px)">
          {navListItems.map(navItem => (
            <SideNavMenuItem {...navItem} key={navItem.navPath} />
          ))}
        </Box>
        <Box margin="16px" marginTop="24px">
          {/* <Button color="primary" width="100%" borderRadius="8px" fontWeight="normal">
            LOGIN FIXME: disabled until login built
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

const SideNavMenuItem: React.FC<Types.NavItem> = ({ navTitle, navPath, sections }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isActive = useIsActiveLocation(navPath);

  return (
    <>
      <Box
        paddingTop="12px"
        paddingBottom="12px"
        paddingLeft="16px"
        backgroundColor={isActive ? 'primary' : 'initial'}
        border="0px solid"
        borderBottomColor={isActive ? 'primary' : 'transparent'}
        borderBottomWidth="1px"
      >
        <Flex justify="space-between">
          <PseudoBox
            as={props => (
              <Link {...props} to={navPath}>
                {navTitle}
              </Link>
            )}
            fontSize={isActive ? '18px' : '14px'}
            fontWeight={isActive ? 'bold' : 'normal'}
            lineHeight="130%"
            _visited={{ textDecoration: 'none' }}
            _first={{ color: 'white' }}
            color="white"
            textDecoration="none"
            aria-label={`go to ${navTitle} page.`}
          />

          {sections && <ChevronToggle open={menuOpen} onClick={setMenuOpen} color="white" />}
        </Flex>
      </Box>
      {sections && menuOpen && <NavSubMenu sections={sections} rootPath={navPath} />}
    </>
  );
};

export default SideNavMenu;
