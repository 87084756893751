import React, { createContext, ReactElement, useContext, useState, useEffect, Dispatch } from 'react';

export interface CurrentUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

type CurrentUserState = null | CurrentUser;
type ProviderProps = { children: React.ReactNode };

/**
 * CREATE THE STATE AND DISPATCH CONTEXTS (initially should be undefined as their values are set when their provider is used.);
 */
const CurrentUserStateContext = createContext<CurrentUserState | undefined>(undefined);
const CurrentUserDispatchContext = createContext<any>(undefined);

// default state - pull the user from localStorage or set to null;
const userFromStorage = localStorage.getItem('ARCH_USER');
const defaultState: CurrentUserState = userFromStorage ? JSON.parse(userFromStorage) : null;

/**
 * CurrentUserProvider - use at the top level to make state/dispatch available to descendants.
 */
export const CurrentUserProvider = ({ children }: ProviderProps): ReactElement => {
  const [state, dispatch] = useState(defaultState);

  return (
    <CurrentUserStateContext.Provider value={state}>
      <CurrentUserDispatchContext.Provider value={dispatch}>{children}</CurrentUserDispatchContext.Provider>
    </CurrentUserStateContext.Provider>
  );
};

/**
 * Use the currentUser value, updates to currentUser will reflect as a state change.
 */
export function useCurrentUser(): null | CurrentUser {
  const context = useContext(CurrentUserStateContext);

  if (context === undefined) {
    throw new Error(`useUser must be used within the CurrentUserProvider`);
  }
  return context;
}

/**
 * get the dispatch function to update the state of currentUser;
 */
export function useCurrentUserDispatch(): any {
  const context = useContext(CurrentUserDispatchContext);
  if (context === undefined) {
    throw new Error(`useUserDispatch must be used within the CurrentUserProvider`);
  }

  return context;
}
