// ref: https://medium.com/better-programming/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
import React, { createContext, useEffect, ReactNode, useState, useContext } from 'react';
import { useTheme } from '@chakra-ui/core';

const BreakpointsContext = createContext<Array<number>>([]);

interface BreakpointsProviderProps {
  children: ReactNode;
}

const BreakpointsProvider: React.FC<BreakpointsProviderProps> = ({ children }) => {
  const { breakpoints } = useTheme();
  const [matchingQueries, setMatchingQueries] = useState<Array<number>>([]);

  useEffect(() => {
    // mediaQueriesList holds matchMedia listeners that flags when a media query is true or false.
    const mediaQueriesList: any = {};

    const reduceMatches = (): void => {
      const matches: Array<number> = [];
      const keys = Object.keys(mediaQueriesList);

      keys.forEach(key => {
        if (mediaQueriesList[key].matches) {
          matches.push(parseInt(key, 10));
        }
      });

      setMatchingQueries(matches);
    };

    if (Array.isArray(breakpoints)) {
      breakpoints.forEach((point: string | any) => {
        mediaQueriesList[point] = window.matchMedia(`(min-width: ${point}em)`);

        // listen for changes to the matching of this point.
        mediaQueriesList[point].addListener(reduceMatches);
      });

      reduceMatches();
    }

    return (): void => {
      const keys = Object.keys(mediaQueriesList);
      keys.forEach(key => {
        mediaQueriesList[key].removeListener(reduceMatches);
      });
    };
  }, [breakpoints]);

  return <BreakpointsContext.Provider value={matchingQueries}>{children}</BreakpointsContext.Provider>;
};

type CurrentBreakpoint = number;
const useCurrentBreakpoint = (): CurrentBreakpoint => {
  const context = useContext(BreakpointsContext);

  if (context === undefined) {
    throw new Error(`useCurrentBreakpoint must be used within a BreakpointsProvider`);
  }

  return context.slice(-1)[0];
};

type ThemeBreakpoints = Array<string> | Array<number>;
// our usage fo the theme breakpoints to compare against the matchMedia context.
const useThemeBreakpointsList = (): ThemeBreakpoints => {
  const { breakpoints } = useTheme();

  if (Array.isArray(breakpoints)) {
    return breakpoints;
  }

  if (typeof breakpoints === 'object') {
    throw new Error('Not handling object style breakpoints yet, need to update BreakpointsContext.');
  }

  return [];
};

// a tuple for the currentBreakpoint and the list of our theme's breakpoints.
export const useBreakpoints = (): [CurrentBreakpoint, ThemeBreakpoints] => {
  const currentBreakpoint = useCurrentBreakpoint();
  const themeBreakpoints = useThemeBreakpointsList();

  return [currentBreakpoint, themeBreakpoints];
};

export const useIsThemeBreakPoint = (i: number): boolean => {
  const [currentBreakPoint, breakpoints] = useBreakpoints();

  return currentBreakPoint >= breakpoints[i];
};

export default BreakpointsProvider;
