import React, { ReactElement } from 'react';
import { Box, Text, PseudoBox, Flex, BoxProps } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { useIsThemeBreakPoint } from 'contexts/Breakpoints';
import ClickableLink from '../ClickableLink';
import { RightArrowSVG } from '../Icons';

/* TS Props Typing */
interface Props {
  name: string;
  shortDescription?: string;
  slug: string;
  lastUpdated?: string;
}

/* The Component */
const ReportTile: React.FC<Props & BoxProps> = (props): ReactElement => {
  const laptop = useIsThemeBreakPoint(1);

  const { name, shortDescription, slug, lastUpdated, maxWidth, ...boxProps } = props;
  return (
    <Box
      {...boxProps}
      borderRadius="16px"
      border="1px solid #E5EAF4"
      boxShadow="0px 15px 35px rgba(0,0,0,0.05)"
      backgroundColor="white"
      maxWidth={laptop ? maxWidth : undefined}
    >
      <Flex height="100%">
        <Flex flex="16" padding="16px" direction="column" height="100%" pos="relative">
          <PseudoBox
            as={(pbProps: any): ReactElement => (
              <Link {...pbProps} to={`reports/${slug}`}>
                <Text fontSize="1.1rem" color="secondary" fontWeight="bold" marginBottom="8px">
                  {name}
                </Text>
              </Link>
            )}
            _hover={{ color: 'purple.400' }}
          />
          {lastUpdated && (
            <Text fontSize="16px" marginBottom="36px" color="textGray">
              updated: <em>{lastUpdated}</em>
            </Text>
          )}
          <Text marginBottom="40px" pos="relative" color="textGray">
            {shortDescription}
          </Text>
          <Box width="100%" textAlign="left" marginTop="16px" marginBottom="24px" pos="absolute" bottom="0">
            <PseudoBox
              as={(pbProps: any): ReactElement => (
                <Flex alignItems="center">
                  <ClickableLink to={`reports/${slug}`} {...pbProps} color="primary" fontWeight="bold">
                    <Text>View Report</Text>
                  </ClickableLink>
                  <Box marginLeft=".5em">
                    <ClickableLink to={`reports/${slug}`}>
                      <RightArrowSVG color="primary" />
                    </ClickableLink>
                  </Box>
                </Flex>
              )}
              aria-label={`view ${name} report`}
              fontSize="16px"
              fontWeight="bold"
              color="primary"
              _hover={{ color: 'blue.600' }}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReportTile;
