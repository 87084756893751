import React from 'react';
import { BoxProps } from '@chakra-ui/core';
import { Report } from 'typings';
import useReportsList from 'hooks/useReportsListQuery';
import SelectMenu from './SelectMenu';

interface Props extends BoxProps {
  value?: string;
  onSelectOption: (val: Report) => void;
}

/* The Component */
const ReportMenu: React.FC<Props> = props => {
  const { value, onSelectOption, ...otherProps } = props;
  const { reports } = useReportsList();

  const filteredList = reports?.filter(r => r.current) || [];
  function findValueInData(): Report | undefined | null {
    if (value && filteredList) {
      return filteredList.find((r: Report) => r.slug === value);
    }
    return null;
  }

  return (
    <SelectMenu
      value={findValueInData()}
      options={filteredList}
      onSelectOption={onSelectOption}
      label="Report Type"
      placeholder="Select a Report"
      optionLabelKey="name"
      optionValueKey="slug"
      {...otherProps}
    />
  );
};

export default ReportMenu;
