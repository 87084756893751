// eslint-disable-next-line spaced-comment
/// <reference types="googlemaps" />

import React, { ReactElement, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, useGoogleMap } from '@react-google-maps/api';
import get from 'lodash.get';
import { School } from 'typings';
import { googleMapApiKey } from '../../../config';
import { highlightAndBoundActiveFeature, onLoad } from './utils';

/* The Component */
interface MapProps {
  data: School[];
  onSelect?: (code: number) => any;
  onSchoolMarkerFocus?: (code: number) => any;
}

const Map: React.FC<MapProps> = ({ data, onSelect, onSchoolMarkerFocus }) => {
  // honolulu
  const lat = get(data[0], 'point.lat') || 21.3069;
  const lng = get(data[0], 'point.lng') || -157.8583;
  const center = { lat, lng };

  // const onSelectFeature = (code: number) => onSelect(code);
  // const onSelectFeature = React.useCallback((code: number) => onSelect(code), [onSelect]);

  return (
    <LoadScript id="script-loader" googleMapsApiKey={googleMapApiKey}>
      <GoogleMap
        id="map"
        center={center}
        mapContainerStyle={{
          height: '100%',
          width: '100%',
        }}
        zoom={data[0] ? 10 : 6}
        onLoad={onLoad}
      >
        <OnClickFeature onSelect={onSelect} />
        <SchoolMarkers schools={data} onFocus={onSchoolMarkerFocus} />
      </GoogleMap>
    </LoadScript>
  );
};

const SchoolMarkers: React.FC<{ schools: School[]; onFocus?: (c: number) => any }> = ({
  schools,
  onFocus,
}): ReactElement | null => {
  const infoWindowRef = useRef<google.maps.InfoWindow>();
  const map = useGoogleMap();

  if (!schools) return null;

  function handleSchoolMarkerEvent(e: google.maps.MouseEvent, school: School) {
    toggleSchoolInfoWindow(e.latLng, school);

    if (onFocus) {
      onFocus(school.code);
    }
  }

  function toggleSchoolInfoWindow(position: google.maps.LatLng, school: School): void {
    if (!map) return;
    if (infoWindowRef?.current) {
      infoWindowRef.current.close();
    }

    infoWindowRef.current = new window.google.maps.InfoWindow({
      content: `${school.name}`,
      position,
    });

    infoWindowRef.current.open(map);
  }

  return (
    <>
      {schools
        .filter(school => school.point?.lat && school.point?.lng)
        .map(school => (
          <Marker
            key={school.code}
            position={{ ...school.point }}
            title={school.name}
            onClick={e => handleSchoolMarkerEvent(e, school)}
            // onClick={() => toggleSchoolInfoWindow(school)}
          />
        ))}
    </>
  );
};

/* updates the onClickFeature event listener as onSelect function may update with its own closure state.
  - ex: on first render -> onSelect references undefined complexData and schoolData as it is still being fetched.
  - ex: on desired render -> onSelect callback is updated because complexData and schoolData are completed.

  - used a component here for access to the useGoogleMap hook;
*/
const OnClickFeature: React.FC<{ onSelect?: (code: number) => any }> = ({ onSelect }): null => {
  const map = useGoogleMap();

  useEffect(() => {
    let listener: google.maps.MapsEventListener;
    if (map && onSelect) {
      listener = map.data.addListener('click', event => {
        const complexCode = event.feature.getProperty('value');
        onSelect(parseInt(complexCode, 10));
        highlightAndBoundActiveFeature(complexCode, map);
      });
    }

    // clean up the listener on each new listener added.
    return () => window.google.maps.event.removeListener(listener);
  }, [map, onSelect]);

  return null;
};

export default Map;
