import React, { ReactElement } from 'react';
import { Text, Flex, Box, BoxProps } from '@chakra-ui/core';
import { useIsThemeBreakPoint } from 'contexts/Breakpoints';
import BackgroundImage from 'components/BackgroundImage';
import MainBackgroundPNG from 'assets/images/mainBackground.png';
import ClickableLink from 'components/ClickableLink';
import { buildVersion, buildID } from 'config';
import DOELogoColor from 'components/Branding/DOELogoColor';

export const ResponsiveRow: React.FC = ({ children }): ReactElement => {
  const laptop = useIsThemeBreakPoint(1);

  return (
    <Flex direction={laptop ? 'row' : 'column'} align={laptop ? 'flex-end' : 'center'} justify="center">
      {children}
    </Flex>
  );
};

export const CenteredPageMaxWidthContainer: React.FC<BoxProps> = ({ children, ...props }): ReactElement => {
  return (
    <Box maxWidth="1150px" paddingX="1em" margin="0 auto" {...props}>
      {children}
    </Box>
  );
};

export const FullWidthBackgroundTitle: React.FC<{ title: string; subtitle?: string } & BoxProps> = ({
  title,
  subtitle,
  ...boxProps
}): ReactElement => {
  return (
    <BackgroundImage src={MainBackgroundPNG} {...boxProps}>
      <CenteredPageMaxWidthContainer paddingTop="4em" paddingBottom="4.8em" minHeight="240px">
        <Text as="h1" color="white" fontSize="2.7rem">
          {title}
        </Text>
        {subtitle && (
          <Box marginTop="1.2em" minHeight="20px">
            <Text as="span" color="white" fontSize="1.2rem">
              {subtitle}
            </Text>
          </Box>
        )}
      </CenteredPageMaxWidthContainer>
    </BackgroundImage>
  );
};

export const PageContainer: React.FC<BoxProps> = ({ children, ...boxProps }): ReactElement => {
  return (
    <Box backgroundColor="gray.100" width="100%" paddingTop="60px" {...boxProps}>
      {/* minHeight: 100vh - headerHeight - footerHeight */}
      <Box minHeight="calc(100vh - 60px - 172px)" margin="0 auto">
        {children}
      </Box>
    </Box>
  );
};

export const ContactFooter = () => (
  <Flex flexDirection="column" alignItems="center" backgroundColor="secondary" paddingY="2.5em">
    <Text color="white" fontSize="1.25em" marginBottom="1.2em">
      If you would like more information or an accessible version of an existing report, please contact us.
    </Text>
    <ClickableLink to="/contact">
      <Box backgroundColor="primary" borderRadius="44px" width="300px" padding="1em" textAlign="center">
        <Text color="white" fontSize=".9rem">
          Contact Us
        </Text>
      </Box>
    </ClickableLink>
  </Flex>
);

export const MainFooter: React.FC = () => {
  const laptop = useIsThemeBreakPoint(1);
  return (
    <Box backgroundColor="darkNavy" paddingY="2em" paddingX={laptop ? undefined : '2em'}>
      <Box maxWidth="maxWidth" margin="0 auto">
        <Flex
          flexDir={laptop ? 'row' : 'column'}
          justifyContent={laptop ? 'space-between' : 'center'}
          alignItems="center"
        >
          <Box>
            <Text color="footerGray">Copyright 2020 State of Hawaii Department of Education</Text>
            <Text color="footerGray">
              Office of Strategy, Innovation and Performance; Assessment and Accountability Branch; Accountability
              Section
            </Text>
            <br />
            <Text color="footerGray">Version#: 0.0.{buildVersion}</Text>
            <Text color="footerGray">Build: {buildID}</Text>
          </Box>
          <DOELogoColor />
        </Flex>
      </Box>
    </Box>
  );
};
