import React, { ReactElement } from 'react';
import Aria from 'components/Aria';
import AboutSection from './components/AboutSection';
import SpotlightSection from './components/SpotlightSection';
import MainSection from './components/MainSection';
import ContactSection from './components/ContactSection';

/* The Component */
const HomePage: React.FC = (): ReactElement => {
  return (
    <>
      <MainSection />
      <AboutSection />
      <Aria.ContentAnchor />
      <SpotlightSection />
      <ContactSection />
    </>
  );
};

export default HomePage;
