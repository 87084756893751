import React, { ReactElement, Children } from 'react';
import { PseudoBox, Link as A, PseudoBoxProps } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

interface Props extends PseudoBoxProps {
  to: string;
  text?: string;
  children?: string | ReactElement | ReactElement[];
  external?: boolean;
  active?: boolean;
  hoverColor?: string;
}

// if CHILDREN, REQUIRES that the text node be a direct child of this component to receive the styles.
const ClickableLink: React.FC<Props> = ({ to, text, children, external, active, hoverColor, ...props }) => {
  return (
    <PseudoBox
      as={pbProps => {
        const childrenWithProps = React.Children.map(
          children,
          child => React.isValidElement(child) && React.cloneElement(child, pbProps),
        );
        return external ? (
          <A {...pbProps} href={to} target="_blank" style={{ textDecoration: 'none' }}>
            {childrenWithProps || text}
          </A>
        ) : (
          <Link {...pbProps} to={to}>
            {childrenWithProps || text}
          </Link>
        );
      }}
      fontSize="desktop.body"
      fontWeight="medium"
      lineHeight="130%"
      _hover={{ color: hoverColor || 'primary' }}
      _visited={{ textDecoration: 'none' }}
      color={active ? 'primary' : 'gray.900'}
      textDecoration="none"
      {...props}
    ></PseudoBox>
  );
};

export default ClickableLink;
