/* eslint-disable no-param-reassign */
import React, { createContext, useContext, ReactElement, useEffect } from 'react';
import { BucketItem, Entity } from 'typings';
import { useParams } from 'react-router';
import { useImmerReducer } from 'use-immer';
import { useQueryStringContext } from '../../contexts/queryStringContext';

/* Create Context: */
const ReportSearchStateContext = createContext<ReportSearchState>({});
const ReportSearchDispatchContext = createContext<React.Dispatch<ReducerAction>>(() => {});

export type EntityFilters = {
  selected?: Entity;
  allowIds?: string[];
};
/* State Object: */
export type ReportSearchState = {
  report?: string;
  year?: number;
  entity?: number;
  bucketItem?: BucketItem;
  bucketLoading?: boolean;
  entityFilters?: EntityFilters;
};

const initialState: ReportSearchState = {
  report: undefined,
  year: undefined,
  entity: undefined,
  bucketItem: undefined,
  entityFilters: undefined,
  bucketLoading: false,
};

export enum ReducerActions {
  FIELD,
  QS_UPDATE,
  YEAR_UPDATE,
  BUCKET_ITEM_SELECTED,
  SET,
  RESET,
}

type ReducerAction = { type: ReducerActions; payload: any; fieldName?: keyof ReportSearchState };
function reportSearchReducer(draft: ReportSearchState, action: ReducerAction): void {
  switch (action.type) {
    case ReducerActions.FIELD:
      if (!action.fieldName) throw Error('fieldName required on fIELD action.');
      draft[action.fieldName] = action.payload;
      break;
    case ReducerActions.QS_UPDATE:
      draft.entity = action.payload.entity;
      draft.year = action.payload.year;
      break;
    case ReducerActions.YEAR_UPDATE:
      draft.year = action.payload;
      draft.bucketItem = undefined;
      break;
    case ReducerActions.BUCKET_ITEM_SELECTED:
      draft.entity = action.payload.entity;
      draft.bucketItem = action.payload.bucketItem;
      break;
    case ReducerActions.RESET:
      draft.report = action.payload.report || '';
      draft.year = undefined;
      draft.entity = undefined;
      draft.bucketItem = undefined;
      draft.entityFilters = undefined;
      break;
    case ReducerActions.SET:
      draft = action.payload;
      break;
    default:
      break;
  }
}

/* Create Context Providers: */
export const ReportSearchProvider: React.FC = ({ children }): ReactElement => {
  const { reportSlug } = useParams();
  const qs = useQueryStringContext();
  const [state, dispatch] = useImmerReducer(reportSearchReducer, initialState);

  useEffect(() => {
    dispatch({ type: ReducerActions.FIELD, fieldName: 'report', payload: reportSlug });
  }, [dispatch, reportSlug]);

  // on qs changes update state values.
  useEffect(() => {
    const stateFromQueryString: ReportSearchState = {
      entity: qs.entityCode,
      year: qs.year,
    };
    dispatch({ type: ReducerActions.QS_UPDATE, payload: stateFromQueryString });
  }, [dispatch, qs]);

  return (
    <ReportSearchStateContext.Provider value={state}>
      <ReportSearchDispatchContext.Provider value={dispatch}>{children}</ReportSearchDispatchContext.Provider>
    </ReportSearchStateContext.Provider>
  );
};

export function useReportSearchState(): ReportSearchState {
  const context = useContext(ReportSearchStateContext);

  if (context === undefined) {
    throw new Error(`useReportSearchState must be used within the ReportSearchProvider`);
  }

  return context;
}

export function useReportSearchDispatch(): React.Dispatch<ReducerAction> {
  const context = useContext(ReportSearchDispatchContext);

  if (context === undefined) {
    throw new Error(`useReportSearchDispatch must be used within the ReportSearchProvider`);
  }

  return context;
}
