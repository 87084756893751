import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/core';

export const PrimaryButton: React.FC<ButtonProps> = props => {
  const { onClick, children, isDisabled, title, ...buttonProps } = props;
  return (
    <Button
      title={isDisabled ? 'You must complete the form.' : title}
      isDisabled={isDisabled}
      backgroundColor="primary"
      color="white"
      onClick={onClick}
      _active={{ bg: 'accent' }}
      _hover={{ bg: 'secondary' }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export const InvertedPrimaryButton: React.FC<ButtonProps> = props => {
  const { onClick, children, ...buttonProps } = props;
  return (
    <Button
      backgroundColor="white"
      color="primary"
      borderColor="primary"
      borderWidth="1px"
      borderStyle="solid"
      height="auto"
      onClick={onClick}
      _hover={{ bg: 'purple.400', color: 'white' }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export const CancelButton: React.FC<ButtonProps> = props => {
  const { onClick, children, ...buttonProps } = props;
  return (
    <Button backgroundColor="primary" color="white" onClick={onClick} _hover={{ color: 'gray.800' }} {...buttonProps}>
      {children}
    </Button>
  );
};
