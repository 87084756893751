import React, { ReactElement } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';
import useReport from 'hooks/useReportQuery';

import {
  PageContainer,
  ResponsiveRow,
  FullWidthBackgroundTitle,
  CenteredPageMaxWidthContainer,
  ContactFooter,
} from 'components/Layout';

import PDFReportSearchForm from 'components/Forms/PDFReportSearchForm';
import Aria from 'components/Aria';
import { ReportSearchProvider } from 'components/Forms/ReportSearchProvider';
import { useMakeToast } from 'components/Toaster/Toaster';
import ClickableLink from 'components/ClickableLink';
import PDFViewer from './PDFViewer';

const ReportPage: React.FC = (): ReactElement => {
  const { reportSlug = '' } = useParams(); // gives us report type ex: 'strive-hi-performance-system'.
  const { report, loading } = useReport(reportSlug);

  return (
    <PageContainer>
      <Aria.ContentAnchor />
      {/* if report is null, probably a bad reportSlug, so redirect away. */}
      <RedirectOnNullData data={report} to={'/reports'} />
      <FullWidthBackgroundTitle title={report ? report.name : ''} />
      <ReportSearchProvider>
        <Box backgroundColor="accent" paddingY="1em" className="whiteLabels">
          <CenteredPageMaxWidthContainer>
            <ResponsiveRow>{report && <PDFReportSearchForm staticReport={reportSlug} />}</ResponsiveRow>
          </CenteredPageMaxWidthContainer>
        </Box>
        <PDFViewer reportSlug={reportSlug} />
      </ReportSearchProvider>
      <ContactFooter />
    </PageContainer>
  );
};

export default ReportPage;

const RedirectOnNullData: React.FC<{ data: any; to: string }> = ({ data, to }): ReactElement | null => {
  const makeToast = useMakeToast();
  if (data === null) {
    makeToast({
      text: `Oops! That report doesn't exist.`,
      subText: `We've redirected back to the reports listing page to pick a suitable report.`,
      status: 'danger',
    });

    return <Redirect to={to} />;
  }

  return null;
};
