import React, { useState, ReactElement, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { Box, Button, Flex, Text, Image } from '@chakra-ui/core';
import { BeatLoader } from 'react-spinners';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash.get';
import { pdfjs, Document, Page } from 'react-pdf';
import { DownloadSVG } from 'components/Icons';
import { useIsThemeBreakPoint } from 'contexts/Breakpoints';
import openInNewTab from 'utilityFns/openInNewTab';
import { useReportSearchState } from 'components/Forms/ReportSearchProvider';
import { s3BucketUrl } from 'config';
import { PrimaryButton } from 'components/Buttons';
import researchDocumentImage from 'assets/images/researchDocumentLarge.png';
import { BucketItem } from '../../typings';
import { CenteredPageMaxWidthContainer } from '../../components/Layout';
import ClickableLink from '../../components/ClickableLink';
import useReport from '../../hooks/useReportQuery';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const GET_BUCKET_ITEMS = gql`
  query getReport($slug: String!, $year: Int!) {
    getReport(slug: $slug) {
      slug
      bucketItems(year: $year) {
        filePath
        filename
        code
        entity {
          __typename
          id
          code
          name
          longName @client
          ... on School {
            district {
              id
              code
            }
            complex {
              id
              code
            }
          }
        }
      }
    }
  }
`;

interface PDFViewerProps {
  reportSlug: string;
}

/* Must be used within report search context. */
const PDFViewer: React.FC<PDFViewerProps> = ({ reportSlug }): ReactElement | null => {
  const desktop = useIsThemeBreakPoint(2);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [tabWasOpened, setTabWasOpened] = useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const { year, bucketLoading, bucketItem } = useReportSearchState();

  const filePath = bucketItem ? `${s3BucketUrl}/${bucketItem.filePath}` : undefined;

  const { data } = useQuery(GET_BUCKET_ITEMS, {
    variables: { slug: reportSlug, year },
    skip: !reportSlug || !year,
  });
  const bucketItems: BucketItem[] = get(data, 'getReport.bucketItems');
  const otherItems: BucketItem[] = bucketItems?.filter(i => !i.entity && i.code !== 999);

  // if there is a filename and on mobile, attempt to open the file in a new tab.
  // if the tab was not already opened, open it.
  useEffect(() => {
    if (filePath && !desktop && !tabWasOpened) {
      openInNewTab(filePath);
      setTabWasOpened(true);
    }
  }, [filePath, desktop, tabWasOpened]);

  const onLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setDocumentLoading(false);
    setPageCount(numPages);
    setPageNumber(1);
  };

  const onFileLoadError = (error: Error): void => {
    console.error('error at pdf load:', error);
  };

  const previousPage = (): void => {
    const prev = pageNumber - 1;
    if (prev < 1) return;
    setPageNumber(prev);
  };

  const nextPage = (): void => {
    const next = pageNumber + 1;
    if (next > pageCount) return;
    setPageNumber(next);
  };

  /* CONDITIONALLY RENDER VIEWS */

  // display the loading icon if the bucketItems are in a loading state.
  if (bucketLoading) {
    return (
      <PdfViewerAreaContainer reportSlug={reportSlug} otherItems={otherItems}>
        <PdfLoader />
      </PdfViewerAreaContainer>
    );
  }

  if (bucketItem?.type === 'BULK_DOWNLOAD') {
    return (
      <PdfViewerAreaContainer reportSlug={reportSlug} otherItems={otherItems}>
        <Text as="h2" marginBottom=".5rem">
          Bulk Downloader
        </Text>
        <Text marginBottom=".5rem">This option allows you to download a .zip file of the reports in the dropdown.</Text>
        <Text marginBottom=".5rem">Click the download button to start downloading.</Text>
        <PrimaryButton
          marginBottom=".5rem"
          onClick={() => {
            console.log('need to implement');
          }}
        >
          <Text color="white">Download&nbsp;</Text>
          <DownloadSVG color="white" />
        </PrimaryButton>
      </PdfViewerAreaContainer>
    );
  }

  // if on mobile, open pdf in new tab, else render pdfViewer.
  if (filePath) {
    if (!desktop) {
      return (
        <Flex margin="82px" direction="column" align="center" justify="center" height="120px">
          <Text>Opened in new tab on mobile.</Text>
          <Button
            onClick={() => filePath && openInNewTab(filePath)}
            background="#4E4A58"
            color="white"
            paddingLeft="24px"
            paddingRight="24px"
            margin="12px"
          >
            <DownloadSVG color="white" />
          </Button>
        </Flex>
      );
    }
    return (
      <PdfViewerAreaContainer reportSlug={reportSlug} otherItems={otherItems}>
        {!documentLoading && (
          <PdfButtonRow
            previousPage={previousPage}
            nextPage={nextPage}
            pageNumber={pageNumber}
            pageCount={pageCount}
            downloadPath={filePath}
            report={reportSlug}
          />
        )}
        <Document
          file={filePath}
          onLoadSuccess={onLoadSuccess}
          onSourceError={onFileLoadError}
          onLoadError={onFileLoadError}
          loading={<PdfLoader />}
          onSourceSuccess={(): void => setDocumentLoading(true)}
        >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
      </PdfViewerAreaContainer>
    );
  }

  // not loading and no bucketItem, render placeholder.
  return (
    <PdfViewerAreaContainer reportSlug={reportSlug} otherItems={otherItems}>
      <PDFViewerPlaceholder />
    </PdfViewerAreaContainer>
  );
};

const PdfLoader: React.FC = (): ReactElement => {
  return (
    <Flex margin="82" height="300px" alignItems="center" justifyContent="center">
      <BeatLoader size={30} margin="0 auto" />
    </Flex>
  );
};

const PDFViewerPlaceholder: React.FC = (): ReactElement => {
  return (
    <Flex margin="4em" direction="column" alignItems="center">
      <Image width="100px" src={researchDocumentImage} alt="Search Reports" />
      <Text as="span" textAlign="center" color="textGray" marginTop="1em">
        Select the report information then click &quot;Search&quot; button to see the pdf.
      </Text>
    </Flex>
  );
};

const PdfViewerAreaContainer: React.FC<{ minHeight?: string; reportSlug: string; otherItems?: BucketItem[] }> = ({
  children,
  minHeight,
  reportSlug,
  otherItems,
}): ReactElement => {
  const laptop = useIsThemeBreakPoint(1);
  const { report } = useReport(reportSlug);

  const isStudentGroupPerformanceReport = reportSlug === 'student-group-performance';
  return (
    <Flex direction="column" align="center" justify="flex-start" minHeight={minHeight || '300px'} paddingTop="24px">
      {children}
      <Box paddingY="4em">
        {report && (
          <CenteredPageMaxWidthContainer>
            <Text color="textGray" lineHeight="26px">
              {report.description}
            </Text>
            {report.externalLink && (
              <Text as="p" color="textGray" marginTop="1.5em">
                For more information go to:{' '}
                <ClickableLink
                  to={report.externalLink}
                  external
                  text={report.externalLink}
                  fontWeight="bold"
                  color="primary"
                />
              </Text>
            )}
            <Text color="textGray" marginTop="1.5em">
              <strong>Last Updated: </strong>
              {report.lastUpdated}
            </Text>
            {otherItems && !isStudentGroupPerformanceReport && (
              <Flex
                marginTop="24px"
                flexDirection={laptop ? 'row' : 'column'}
                flexWrap={'wrap'}
                alignItems={!laptop ? 'center' : ''}
              >
                {otherItems.map(
                  (item, index): ReactElement => {
                    return <DownloadReportButton key={index} filePath={item.filePath} filename={item.filename} />;
                  },
                )}
              </Flex>
            )}
          </CenteredPageMaxWidthContainer>
        )}
      </Box>
    </Flex>
  );
};

interface ButtonRowProps {
  previousPage: any;
  nextPage: any;
  pageNumber: any;
  pageCount: any;
  downloadPath: any;
  report: string;
}
const PdfButtonRow: React.FC<ButtonRowProps> = ({
  previousPage,
  nextPage,
  pageNumber,
  pageCount,
  downloadPath,
  report,
}): ReactElement => {
  const isStudentGroupPerformanceReport = report === 'student-group-performance';
  const top = isStudentGroupPerformanceReport ? '290px' : '792px';
  const position = isStudentGroupPerformanceReport ? 'absolute' : 'sticky';
  const downloadButtonOffset = isStudentGroupPerformanceReport ? '458px' : '388px';
  const prevOffset = isStudentGroupPerformanceReport ? '520px' : '450px';
  const nextOffset = isStudentGroupPerformanceReport ? '470px' : '400px';

  return (
    <Box width="100%" height="792px" position="absolute">
      <Button
        onClick={(): void => downloadPath && openInNewTab(downloadPath)}
        background="#4E4A58"
        color="white"
        paddingLeft="24px"
        paddingRight="24px"
        position="absolute"
        top="0"
        left={`calc(50% + ${downloadButtonOffset})`}
      >
        <DownloadSVG color="white" />
      </Button>
      <Button
        onClick={previousPage}
        isDisabled={pageNumber === 1}
        background="#4E4A58"
        color="white"
        paddingLeft="24px"
        paddingRight="24px"
        position={position}
        top={top}
        left={`calc(50% - ${prevOffset})`}
      >
        &lt;
      </Button>
      <Button
        onClick={nextPage}
        isDisabled={pageNumber === pageCount}
        background="#4E4A58"
        color="white"
        paddingLeft="24px"
        paddingRight="24px"
        position={position}
        top={top}
        left={`calc(50% + ${nextOffset})`}
      >
        &gt;
      </Button>
    </Box>
  );
};

const ErrorDisplay = (): ReactElement => {
  return (
    <Box textAlign="center">
      <Text>Oops! An Error Occurred.</Text>
      <Text>Please reload the webpage to try again.</Text>
    </Box>
  );
};

const makeUrl = (filePath: string): string => {
  return `${s3BucketUrl}/${filePath}`.replace(/\s/g, '+');
};

const DownloadReportButton: React.FC<{ filePath: string; filename: string }> = ({
  filePath,
  filename,
}): ReactElement => {
  return (
    <a href={makeUrl(filePath)} target="_blank" rel="noopener noreferrer">
      <Box
        marginRight="16px"
        marginBottom="16px"
        border="1px solid"
        borderRadius="8px"
        borderColor="primary"
        padding="12px"
        whiteSpace="nowrap"
      >
        <Flex align="center">
          <Box flex="1">
            <DownloadSVG color="black" />
          </Box>
          <Flex flex="4" padding="16px" flexDir="column" justify="space-between">
            <Text color="primary" fontWeight="bold">
              DOWNLOAD
            </Text>
            <Text color="primary">{filename}</Text>
          </Flex>
        </Flex>
      </Box>
    </a>
  );
};

export default PDFViewer;
