import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { QueryStringProvider } from 'contexts/queryStringContext';
import LoginPage from 'pages/LoginPage';
import Aria from 'components/Aria';
import { useCurrentUser } from './contexts/currentUser';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import ADCRedirectPage from './pages/ADCRedirectPage';
import ReportsListPage from './pages/ReportsListPage';
import SchoolsSearchPage from './pages/SchoolsSearchPage';
import FourOFourPage from './pages/FourOFourPage';

/* SECURED pages should be used with SecuredRoute Component */
import AdminPage from './pages/secured/AdminPage';
import ManageReportPage from './pages/secured/ManageReportPage';
import Header from './components/Header';
import { MainFooter } from './components/Layout';
import ReportPage from './pages/ReportPage';
import DataBookReportPage from './pages/DataBookReportPage';

/* The Component */
/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */
const Router: React.FC = () => {
  return (
    <BrowserRouter>
      {/* QS Provider here for access to useLocation router hook. */}
      <QueryStringProvider>
        <Aria.ContentAnchorLink />
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/adc-redirect">
            <ADCRedirectPage />
          </Route>
          <Route path="/schools">
            <SchoolsSearchPage />
          </Route>
          <Route path="/reports/hidoe-data-book">
            <DataBookReportPage slug="hidoe-data-book" />
          </Route>
          <Route path="/reports/annual-report">
            <DataBookReportPage slug="annual-report" />
          </Route>
          <Route path="/reports/:reportSlug">
            <ReportPage />
          </Route>
          <Route path="/reports">
            <ReportsListPage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <SecuredRoute path="/admin/manage-report/:reportSlug">
            <ManageReportPage />
          </SecuredRoute>
          <SecuredRoute path="/admin">
            <AdminPage />
          </SecuredRoute>
          <Route>
            <FourOFourPage />
          </Route>
        </Switch>
        <MainFooter />
      </QueryStringProvider>
    </BrowserRouter>
  );
};

export default Router;

/*
-------------------------------------------------------------------------------------------
PRIVATE ROUTE COMPONENT
-------------------------------------------------------------------------------------------
*/
const SecuredRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const user = useCurrentUser();

  return (
    <Route
      {...rest}
      render={({ location }): RouteProps['children'] | Redirect =>
        user ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    ></Route>
  );
};

SecuredRoute.propTypes = {
  children: PropTypes.node,
};

/*
-------------------------------------------------------------------------------------------
UTILITY COMPONENTS
-------------------------------------------------------------------------------------------
*/

/* Scroll To Top on page change. 
 - used a component so it had scope to useLocation router hook. 
*/
const ScrollToTop: React.FC = (): null => {
  const { pathname } = useLocation();
  const prevLocation = useRef<string>('');

  useEffect(() => {
    if (prevLocation.current !== pathname) {
      window.scrollTo(0, 0);

      prevLocation.current = pathname;
    }
  }, [pathname]);

  return null;
};
