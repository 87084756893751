import React, { ReactElement } from 'react';
import { Box, Text, Flex, Link } from '@chakra-ui/core';

const roundedBottomStyle: any = {
  position: 'relative',
  borderRadius: '0 0 50% 50%',
  width: '110vw',
  marginLeft: '-5vw',
  marginRight: '-5vw',
  overflow: 'hidden',
};
/* The Component */
const AboutSection: React.FC = (): ReactElement => {
  return (
    <Box backgroundColor="#F8FBFF" width="100%" overflow="hidden">
      <Box style={roundedBottomStyle} backgroundColor="white" paddingBottom="75px">
        <Box maxWidth="maxWidth" height="100%" margin="0 auto">
          <Flex direction="column" align="center" textAlign="left">
            <Box marginBottom="40px" width="80%">
              <Text as="h2" color="primary" textAlign="center" marginBottom="20px">
                About ARCH
              </Text>
              <Text color="textGray">
                ARCH provides school, complex area, and state reports. These reports present a wide array of
                accountability and school quality information. The majority of these reports satisfy federal and state
                requirements, and keep the general public informed on school performance and progress.
              </Text>
              <br />
              <Text color="textGray">
                ARCH users include parents, school administrators, state support staff and departmental leadership, the
                Board of Education, state lawmakers, the business community, researchers, and other stakeholders. These
                reports and the data contained within are intended to help inform efforts to support and improve
                Hawaii’s public schools.
              </Text>
              <br />
              <Text color="textGray">
                Various documents on this site require Adobe Acrobat Reader DC or a compatible PDF reader to view them.
                Adobe Acrobat Reader DC is a free program and can be downloaded{' '}
                <Link color="teal.500" href="https://www.adobe.com/acrobat/pdf-reader.html">
                  here
                </Link>
                .
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutSection;
