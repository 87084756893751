import React, { useState, ReactElement, ReactNode } from 'react';
import { Box, Flex, Text, Collapse } from '@chakra-ui/core';
import ChevronToggle from '../Icons/ChevronToggle';
import AccordionNavLink, { AccordionNavLinkProps } from './AccordionNavLink';

export interface AccordionProps {
  title: string;
  children: ReactNode;
  open?: boolean;
  highlight?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ title, open = false, children, highlight }): ReactElement => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <Box
      background="white"
      borderRadius="8px"
      shadow="light"
      marginTop="24px"
      paddingTop="24px"
      paddingBottom="24px"
      borderWidth={highlight ? '.25rem' : undefined}
      borderColor="pink.200"
    >
      <Flex justify="space-between">
        <Box onClick={(): void => setIsOpen(!isOpen)} marginLeft="24px" cursor="pointer">
          <Text lineHeight="24px">{title.toUpperCase()}</Text>
        </Box>
        <Box marginRight="24px">
          <ChevronToggle open={isOpen} onClick={(): void => setIsOpen(!isOpen)} />
        </Box>
      </Flex>
      <Collapse marginTop={4} isOpen={isOpen}>
        {children}
      </Collapse>
    </Box>
  );
};

export const AccordionNavItem: React.FC<AccordionNavLinkProps> = (props): ReactElement => {
  return (
    <Flex align="center" padding="24px 0 24px 0" borderBottomWidth="1px" borderBottomColor="gray.400">
      <Box backgroundColor="blue.400" width="8px" height="8px" borderRadius="8px" marginLeft="24px" />
      <AccordionNavLink {...props} />
    </Flex>
  );
};

export default Accordion;
