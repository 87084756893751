import React, { ReactElement } from 'react';
import { Box, Text, Grid, Flex } from '@chakra-ui/core';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import { RightArrowSVG } from 'components/Icons';
import ClickableLink from 'components/ClickableLink';
import { Report, School } from '../../typings';
import Accordion from '../../components/Accordion';

const GET_REPORTS_NAV_QUERY = gql`
  query getReportsList {
    getReportsList {
      type
      name
      slug
      defaultYear {
        year
        filename
      }
    }
  }
`;

interface SchoolAccordionProps extends School {
  open?: boolean;
  highlight?: boolean;
}

const SchoolAccordion: React.FC<SchoolAccordionProps> = ({ code, name, closed, open, highlight }): ReactElement => {
  const { data, loading } = useQuery(GET_REPORTS_NAV_QUERY);

  const title = closed ? `${name} (closed)` : name;

  return (
    <Accordion title={title} open={open} highlight={highlight}>
      <Box paddingX="1.25em">
        <Text fontWeight="bold">School reports</Text>
        <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap="6">
          {data &&
            data.getReportsList
              .filter((r: Report) => r.type === 'SCHOOL')
              .map(
                ({ slug, name: reportName, defaultYear }: Report): ReactElement => (
                  <SchoolAccordionItem
                    key={slug}
                    to={`reports/${slug}?year=${defaultYear &&
                      defaultYear.year}&entity=${code}&filename=${defaultYear && defaultYear.filename}`}
                    title={reportName}
                  />
                ),
              )}
        </Grid>
      </Box>
    </Accordion>
  );
};

interface SchoolAccordionItemProps {
  to: string;
  title: string;
}

const SchoolAccordionItem: React.FC<SchoolAccordionItemProps> = ({ to, title }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      padding=".5em"
      border="1px solid"
      borderColor="softGray"
      borderRadius="6px"
      height="80px"
    >
      <ClickableLink to={to}>
        <Text as="span">{title}</Text>
      </ClickableLink>
      <ClickableLink to={to}>
        <RightArrowSVG color="primary" />
      </ClickableLink>
    </Flex>
  );
};

export default SchoolAccordion;
