import React, { createContext, useEffect, useState, useContext, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

type QueryStringContext = {
  year?: number;
  entityCode?: number;
  filename?: string;
};

const QueryStringContext = createContext<QueryStringContext>({});

export const QueryStringProvider: React.FC = ({ children }): ReactElement => {
  const qs = useLocation().search;
  const [search, setSearch] = useState<QueryStringContext>({});

  useEffect(() => {
    const params = new URLSearchParams(qs);

    const year = params.get('year');
    const entityCode = params.get('entity');
    const filename = params.get('filename') || undefined;

    setSearch({
      year: year ? parseInt(year, 10) : undefined,
      entityCode: entityCode ? parseInt(entityCode, 10) : undefined,
      filename,
    });
  }, [qs]);

  return <QueryStringContext.Provider value={search}>{children}</QueryStringContext.Provider>;
};

export function useQueryStringContext(): QueryStringContext {
  const context = useContext(QueryStringContext);

  if (context === undefined) {
    throw new Error(`useCurrentUrl must be used within the CurrentUrlProvider`);
  }
  return context;
}
