import React from 'react';
import { useLocation } from 'react-router-dom';
import { Text } from '@chakra-ui/core';
import {
  PageContainer,
  FullWidthBackgroundTitle,
  CenteredPageMaxWidthContainer,
  ContactFooter,
} from 'components/Layout';

/* The Component */
const FourOFourPage: React.FC = () => {
  const location = useLocation();

  return (
    <PageContainer>
      <FullWidthBackgroundTitle title="Page Not Found" />
      <CenteredPageMaxWidthContainer minHeight="300px" paddingY="1em">
        <Text>Whoops! The page &quot;{location.pathname}&quot; doesn&apos;t exist.</Text>
      </CenteredPageMaxWidthContainer>
      <ContactFooter />
    </PageContainer>
  );
};

export default FourOFourPage;
