import React, { ReactElement } from 'react';
import { Year, BucketItem } from 'typings';
import { useReportSearchState, useReportSearchDispatch, ReducerActions } from 'components/Forms/ReportSearchProvider';
import EntityFilterMenu from 'components/Menus/EntityFilterMenu';
import { useIsThemeBreakPoint } from '../../contexts/Breakpoints';

import AvailableYearsMenu from '../Menus/AvailableYearsMenu';
import ReportBucketMenu from '../Menus/ReportBucketMenu';
import { CancelButton } from '../Buttons';

interface PDFReportSearchFormProps {
  staticReport: string;
}

const PDFReportSearchForm: React.FC<PDFReportSearchFormProps> = ({ staticReport }): ReactElement => {
  const laptop = useIsThemeBreakPoint(1);
  const { report, year, entity, bucketItem, entityFilters } = useReportSearchState();
  const dispatch = useReportSearchDispatch();

  const updateFieldOnState = React.useCallback(
    (fieldName, payload) => dispatch({ type: ReducerActions.FIELD, fieldName, payload }),
    [dispatch],
  );

  const updateYearOnState = React.useCallback(payload => dispatch({ type: ReducerActions.YEAR_UPDATE, payload }), [
    dispatch,
  ]);

  const updateBucketItemOnState = React.useCallback(
    payload => dispatch({ type: ReducerActions.BUCKET_ITEM_SELECTED, payload }),
    [dispatch],
  );

  return (
    <>
      <AvailableYearsMenu
        slug={report}
        value={year}
        onSelectOption={(selected: Year): void => {
          updateYearOnState(selected.year);

          // If "Filter by" selection is a translation and year is less than 2020, clear the entity filter.
          if (entityFilters?.selected?.__typename === 'Translation' && selected.year < 2020) {
            updateFieldOnState('entityFilters', { selected: undefined });
          }
        }}
        paddingRight={laptop ? '16px' : undefined}
        paddingTop=".5rem"
      />
      <EntityFilterMenu
        slug={report}
        year={year}
        value={entityFilters && entityFilters.selected}
        onSelectOption={(filters): void => updateFieldOnState('entityFilters', filters)}
        paddingRight={laptop ? '16px' : undefined}
        paddingTop=".5rem"
      />
      <ReportBucketMenu
        slug={report}
        year={year}
        value={entity}
        bucketItem={bucketItem}
        filters={entityFilters && entityFilters.allowIds}
        onSelectOption={(selected?: BucketItem): void =>
          updateBucketItemOnState({ entity: selected?.code, bucketItem: selected })
        }
        onLoading={bucketLoading => updateFieldOnState('bucketLoading', bucketLoading)}
        paddingRight={laptop ? '16px' : undefined}
        paddingTop=".5rem"
      />
      <CancelButton
        title="Clear the form"
        onClick={(): void =>
          dispatch({
            type: ReducerActions.RESET,
            payload: { report: staticReport, year: undefined, entity: undefined, bucketItem: undefined },
          })
        }
        width={laptop ? '275px' : '100%'}
        marginTop={laptop ? '' : '1em'}
        marginBottom=".25em"
      >
        Clear Filters
      </CancelButton>
    </>
  );
};

export default PDFReportSearchForm;
