import React, { ReactElement } from 'react';
import { Box, Text, ListIcon, Flex } from '@chakra-ui/core';
import { PrimaryButton } from 'components/Buttons';
import { CenteredPageMaxWidthContainer, FullWidthBackgroundTitle, PageContainer } from 'components/Layout';

/* The Component */
const ADCRedirectPage: React.FC = (): ReactElement => {
  return (
    <PageContainer backgroundColor="secondary">
      <FullWidthBackgroundTitle title="Accountability Data Center" />
      <CenteredPageMaxWidthContainer paddingY="5em">
        <Box background="white" borderRadius=".5rem" shadow="light" padding="4rem" maxWidth="50rem" margin="0 auto">
          <Text color="gray.900" marginTop="1.5rem">
            The purpose of the ADC (Accountability Data Center) is to provide helpful information on school and student
            performance for Hawaii’s public schools in a user-engaged, visual format. Key functions include:
          </Text>
          <br />
          <Box marginLeft="1rem">
            <Flex color="gray.900">
              <Box>
                <ListIcon icon="check-circle" color="primary" />
              </Box>
              <Box>Visualization analytics for participation, achievement, graduation and retention data</Box>
            </Flex>
            <Flex color="gray.900" marginTop=".5rem">
              <Box>
                <ListIcon icon="check-circle" color="primary" />
              </Box>
              <Box>Subgroup and school comparison functions</Box>
            </Flex>
            <Flex color="gray.900" marginTop=".5rem">
              <Box>
                <ListIcon icon="check-circle" color="primary" />
              </Box>
              <Box>
                Secured access to student-level data across measures used for Hawaii’s Strive HI system (internal use
                only)
              </Box>
            </Flex>
          </Box>
          <br />
          <Flex justifyContent="center">
            <PrimaryButton title="Head to ADC Website">
              <a href="https://adc.hidoe.us" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                Go to ADC Website
              </a>
            </PrimaryButton>
          </Flex>
        </Box>
      </CenteredPageMaxWidthContainer>
    </PageContainer>
  );
};

export default ADCRedirectPage;
