import React, { useState } from 'react';

import { Image, ImageProps, Box } from '@chakra-ui/core';

import DoeLogoPNG from './doeLogo.png';

/* The Component */
const DOELogoColor: React.FC<ImageProps> = props => {
  const [hidden, setHidden] = useState(true);
  return (
    <Box minWidth="56px" minHeight="56px">
      <Image
        hidden={hidden}
        src={DoeLogoPNG}
        alt="Department of Education State of Hawaii"
        {...props}
        onLoad={() => setHidden(false)}
      />
    </Box>
  );
};

export default DOELogoColor;
