import { InMemoryCache, defaultDataIdFromObject, CacheResolverMap, IntrospectionFragmentMatcher } from 'apollo-boost';

import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

/*
 * a switch over __typename to change the client cache ID for that object.
 * default: __typename:ID (from the id or _id field)
 *
 * case-by-case basis:
 * ex: Report becomes Report:strive-hi-performance-system
 */
const dataIdFromObject = (object: any): string | null | undefined => {
  // eslint-disable-next-line no-underscore-dangle
  switch (object.__typename) {
    case 'Report':
      return `Report:${object.slug}`;
    default:
      return defaultDataIdFromObject(object);
  }
};

const cacheRedirects: CacheResolverMap = {
  Query: {
    report: (_, args, { getCacheKey }): any => {
      return getCacheKey({ __typename: 'Report', slug: args.slug });
    },
  },
};

const cache = new InMemoryCache({
  dataIdFromObject,
  cacheRedirects,
  fragmentMatcher,
});

export default cache;
