import React, { ReactElement, useState } from 'react';
import { Box, Text, Grid, Image, Flex } from '@chakra-ui/core';
import { CenteredPageMaxWidthContainer, FullWidthBackgroundTitle, PageContainer } from 'components/Layout';

import GirlOnComputer from '../assets/images/girloncomputer1.jpg';

/* The Component */
const AboutPage: React.FC = (): ReactElement => {
  const [hidden, setHidden] = useState(true);

  return (
    <PageContainer backgroundColor="secondary">
      <FullWidthBackgroundTitle title="About ARCH" />
      <CenteredPageMaxWidthContainer>
        <Grid templateColumns="repeat(auto-fit, minmax(350px, 1fr))" gap={6} paddingY="3em">
          <Box background="white" borderRadius="8px" shadow="light" padding="40px">
            <Text color="gray.900" marginTop="28px">
              ARCH provides school, complex area, and state reports. These reports present a wide array of
              accountability and school quality information. The majority of these reports satisfy federal and state
              requirements, and keep the general public informed on school performance and progress.
              <br />
              <br /> Users include parents, school administrators, state support staff and departmental leadership, the
              Board of Education, state lawmakers, the business community, researchers, and other stakeholders. These
              reports and the data contained within are intended to help inform efforts to support and improve Hawaii’s
              public schools.
            </Text>
            <br />
          </Box>
          <Box hidden={hidden}>
            <Image
              src={GirlOnComputer}
              alt="Picture of students and general learner outcomes"
              onLoad={() => setHidden(false)}
              borderRadius="8px"
            />
          </Box>
          <Box hidden={!hidden} />
        </Grid>
      </CenteredPageMaxWidthContainer>
    </PageContainer>
  );
};

/* Runtime React propTypes */
AboutPage.propTypes = {};

export default AboutPage;
