import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link, PseudoBox } from '@chakra-ui/core';

/* The Component */
const ContentAnchorLink: React.FC = () => {
  function setMainContentFocus(): void {
    return document.getElementById('ariaMainContent')?.focus();
  }

  return (
    <PseudoBox
      pos="absolute"
      zIndex={10000}
      as={pbProps => (
        <HashLink
          to="#ariaMainContent"
          style={{ left: -500, padding: '1rem' }}
          {...pbProps}
          onClick={setMainContentFocus}
        >
          Skip to Main Content
        </HashLink>
      )}
      // @ts-ignore
      _focus={{ left: '0 !important', backgroundColor: 'white' }}
    />
  );
};

const ContentAnchor: React.FC = () => {
  return (
    <PseudoBox
      position="relative"
      as={pbProps => (
        <Link
          id="ariaMainContent"
          style={{ left: -500, padding: '0rem', height: '0px !important' }}
          {...pbProps}
          tabIndex="0"
        ></Link>
      )}
      _focus={{ left: '0 !important', backgroundColor: 'white' }}
    />
  );
};

const Aria = { ContentAnchorLink, ContentAnchor };

export default Aria;
