import 'toasted-notes/src/styles.css';
import React, { createContext, ReactElement, useContext } from 'react';
import toast from 'toasted-notes';
import { Box, Text } from '@chakra-ui/core';

type Status = 'info' | 'danger' | 'success' | 'warning';
type ToastObj = { text: string; subText?: string; status: Status };

function makeToast(t: ToastObj): void {
  toast.notify(({ onClose }) => <ToastBox {...t} onClose={onClose} />);
}

const MakeToastContext = createContext<(t: ToastObj) => void>(makeToast);
const Toaster: React.FC = ({ children }): ReactElement => {
  return <MakeToastContext.Provider value={makeToast}>{children}</MakeToastContext.Provider>;
};

export const useMakeToast = (): ((t: ToastObj) => void) => {
  const context = useContext(MakeToastContext);

  if (context === undefined) {
    throw new Error(`useMakeToast must be used within a MakeToastProvider`);
  }

  return context;
};

const toastThemes = {
  info: '#25A9EF',
  danger: '#EB5757',
  success: '#10C971',
  warning: '#F2994A',
};
const ToastBox: React.FC<ToastObj & { onClose: any }> = ({ text, subText, status, onClose }) => {
  return (
    <Box background={toastThemes[status]} padding=".5rem" borderRadius="5px">
      <Text color="white" fontWeight="bold" textAlign="center" marginTop=".25rem" marginBottom=".5rem">
        {text}
      </Text>
      <Text color="white" textAlign="left" maxWidth="300px">
        {subText}
      </Text>
    </Box>
  );
};

export default Toaster;
