import { gql } from 'apollo-boost';
import { Entity } from 'typings';

export const typeDefs = gql`
  extend type District {
    longName: String!
  }
  extend type ComplexArea {
    longName: String!
  }
  extend type Complex {
    longName: String!
  }
`;

interface Resolvers {
  [key: string]: {
    [field: string]: (rootValue?: any, args?: any, context?: any, info?: any) => any;
  };
}

export const resolvers: Resolvers = {
  Query: {},
  Mutation: {},
  Entity: {
    longName: (parent: Entity) => {
      const { name } = parent;
      return name;
    },
  },
  Complex: {
    longName: (parent: Entity) => {
      const { name } = parent;
      return `${name} Complex`;
    },
  },
  ComplexArea: {
    longName: (parent: Entity) => {
      const { name } = parent;
      return `${name} Complex Area`;
    },
  },
  District: {
    longName: (parent: Entity) => {
      const { name } = parent;
      return `${name} District`;
    },
  },
  School: {
    longName: (parent: Entity) => {
      return parent.name;
    },
  },
};
