import React from 'react';
import { Text } from '@chakra-ui/core';

export const TitleText: React.FC = () => (
  <Text as="h1" color="white" lineHeight="130%" letterSpacing="-2px">
    Accountability Resource Center Hawaii
  </Text>
);

export const DOEText: React.FC = () => (
  <Text as="span" fontSize="1.4rem" color="white" letterSpacing="2px">
    STATE OF HAWAII DEPARTMENT OF EDUCATION
  </Text>
);
