import React from 'react';
import useReportsList from 'hooks/useReportsListQuery';
import ReportGrid from 'components/ReportsGrid';
import { Box, Text } from '@chakra-ui/core';
import ClickableLink from 'components/ClickableLink';
import { useIsThemeBreakPoint } from 'contexts/Breakpoints';
import { CenteredPageMaxWidthContainer } from 'components/Layout';

/* The Component */
const SpotlightSection: React.FC = () => {
  const laptop = useIsThemeBreakPoint(1);
  const { reports, loading } = useReportsList();

  return (
    <Box
      background="linear-gradient(180deg, #F8FBFF 0%, rgba(250, 251, 251, 0.0001) 100%)"
      padding={laptop ? '4em' : '1em'}
      minHeight="85vh"
    >
      <Text as="h2" color="textGray" textAlign="center" marginBottom=".5em">
        Reports
      </Text>
      <Text as="p" color="textGray" fontSize="1.1rem" textAlign="center" marginBottom="2em">
        Find out how Hawaii public schools are performing
      </Text>
      <CenteredPageMaxWidthContainer>
        <ReportGrid reports={reports} loading={loading} />
      </CenteredPageMaxWidthContainer>
    </Box>
  );
};

export default SpotlightSection;
