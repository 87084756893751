import React, { ReactElement, useState } from 'react';
import { Text, Box, Input, Flex } from '@chakra-ui/core';
import { PageContainer } from 'components/Layout';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PrimaryButton } from 'components/Buttons';
import axios from 'axios';
import { apiEndpoint } from 'config';
import { useCurrentUserDispatch, useCurrentUser } from 'contexts/currentUser';
import { Redirect, useLocation } from 'react-router-dom';
import { UserRoleEnum } from 'typings/arch';
import { BasicError } from 'components/Errors';
import { ARCHError } from 'typings';

/* The Component */
const index: React.FC = () => {
  return (
    <PageContainer>
      <Flex alignItems="center" justifyContent="center" minHeight="inherit">
        <Box flex="1" maxWidth="600px" background="white" padding="2rem" borderRadius="8px" shadow="light">
          <Text fontSize="desktop.h3" fontWeight="bold" color="primary" marginBottom="1rem">
            Administrator
          </Text>
          <LoginForm />
        </Box>
      </Flex>
    </PageContainer>
  );
};

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const [error, setError] = useState<ARCHError>();
  const currentUser = useCurrentUser();
  const dispatchUser = useCurrentUserDispatch();
  const location = useLocation();

  const { state = {} }: any = location;
  const initialValues: LoginFormValues = { email: '', password: '' };

  const attemptLogin = async (loginCredentials: LoginFormValues) => {
    const res = await axios.post(`${apiEndpoint}/auth/login`, loginCredentials).catch(({ response }) => {
      if (response.status === 401) {
        setError({ message: `That's not correct.` });
      } else {
        setError({ message: 'Oops! Something went wrong.' });
      }
    });

    if (res && res.data) {
      const { token, user } = res.data;

      localStorage.setItem('ARCH_TOKEN', token);
      localStorage.setItem('ARCH_USER', JSON.stringify(user));

      dispatchUser(user);
    }
  };

  if (currentUser) {
    if (state.from) {
      return <Redirect to={state.from} />;
    }
    if (currentUser.role === UserRoleEnum.ADMIN) {
      return <Redirect to="/admin" />;
    }
    // else: just continue rendering the login page - we only allow admin's logged in.
  }

  return (
    <Box>
      <Box paddingY=".5rem">
        <BasicError error={error} />
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={attemptLogin}
        validate={values => {
          const errors = {} as any;
          if (!values.email) errors.email = 'Must provide an email.';
          if (!values.password) errors.password = 'Must provide a password';

          return errors;
        }}
        render={fk => (
          <Form>
            <Field
              name="email"
              render={({ field, form, meta }: any): ReactElement => (
                <Box minHeight="4rem">
                  <Input type="text" {...field} placeholder="email" borderColor="hsl(0,0%,80%)" color="black" />
                  <Box>
                    <ErrorMessage name="email">{message => <BasicError error={{ message }} />}</ErrorMessage>
                  </Box>
                </Box>
              )}
            />
            <Field
              name="password"
              render={({ field, form, meta }: any): ReactElement => (
                <Box minHeight="4rem" marginY={'1rem'}>
                  <Input type="password" {...field} placeholder="password" borderColor="hsl(0,0%,80%)" color="black" />
                  <Box>
                    <ErrorMessage name="password">{message => <BasicError error={{ message }} />}</ErrorMessage>
                  </Box>
                </Box>
              )}
            />
            <Flex justifyContent="flex-end" marginTop="2rem">
              <PrimaryButton type="submit">Login</PrimaryButton>
            </Flex>
          </Form>
        )}
      />
    </Box>
  );
};

export default index;
