import { useTheme } from '@chakra-ui/core';

/**
 *
 * @param color a string to extract the hex code from the theme.
 * @returns 'currentColor' || the hex code from the theme.
 */
const useThemeColor = (color?: string): string => {
  let theme: any = { colors: {} }; // to override the ts error.
  theme = useTheme();

  let themeColor = 'currentColor';
  if (color && color.includes('.')) {
    const val = color.split('.');
    themeColor = theme.colors[val[0]][val[1]];
  } else if (color) {
    themeColor = theme.colors[color];
  }

  return themeColor;
};

export default useThemeColor;
