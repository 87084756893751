import React, { useEffect } from 'react';
import Select, { Styles } from 'react-select';
import { Box, Text, PseudoBox, useTheme, BoxProps } from '@chakra-ui/core';
import ReactToolTip from 'react-tooltip';

const colourStyles = (colors: any): Partial<Styles> => {
  return {
    valueContainer: (styles: any): any => ({ ...styles, paddingTop: '14px' }),
    control: (styles: any): any => ({ ...styles, backgroundColor: 'white' }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any): any => {
      return {
        ...styles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isSelected ? colors.blue[400] : isFocused ? colors.blue[100] : null,
        color: isSelected ? colors.white : colors.gray[900],
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
        },
      };
    },
  };
};

interface SelectMenuProps {
  value?: any;
  options: Array<any>;
  label: string;
  labelColor?: string;
  optionLabelKey: string;
  optionValueKey: string;
  onSelectOption: (val: any) => void;
  autoSelectFirstOption?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  ariaLabel?: string;
  ariaLabelledBy?: string;
}
/* The Component */
const SelectMenu: React.FC<SelectMenuProps & BoxProps> = props => {
  const {
    value,
    options,
    label,
    labelColor,
    placeholder,
    optionLabelKey,
    optionValueKey,
    onSelectOption,
    autoSelectFirstOption,
    isDisabled,
    isLoading,
    isClearable,
    ariaLabel,
    ariaLabelledBy,
    ...boxProps
  } = props;
  const { colors } = useTheme();

  // if the autoSelect flag is set, select the first value if none is provided.
  useEffect(() => {
    if (options && options.length > 0 && autoSelectFirstOption && !value) {
      onSelectOption(options[0]);
    }
  }, [autoSelectFirstOption, onSelectOption, optionValueKey, options, value]);

  const selectOption = (selected: any): void => {
    if (selected !== value) {
      onSelectOption(selected);
    }
  };

  // eslint-disable-next-line no-useless-escape
  const labelStr = label.replace(/\s/g, '');
  const uniqueToolTipId = `toolTip${labelStr}`;
  const uniqueSelectMenuId = `selectMenu${labelStr}`;

  return (
    <Box width="100%" {...boxProps} data-tip="type or scroll to find an option" data-for={uniqueToolTipId}>
      <ReactToolTip delayShow={500} id={uniqueToolTipId} place="bottom" />
      <PseudoBox
        as={pbProps => (
          <label {...pbProps} htmlFor={uniqueSelectMenuId}>
            {label}
          </label>
        )}
        color={isDisabled ? 'accent' : labelColor || undefined}
        id={labelStr}
      />
      {/* <Text>
        {label}
      </Text> */}
      <Select
        value={value || null}
        onChange={selectOption}
        isClearable={isClearable}
        options={options || undefined}
        noOptionsMessage={() => 'No Options Available'}
        styles={colourStyles(colors)}
        getOptionLabel={(option: any): string => `${option[optionLabelKey]}`}
        getOptionValue={(option: any): string => `${option[optionValueKey]}`}
        name={label}
        id={uniqueSelectMenuId}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isLoading={isLoading}
        aria-labelledby={labelStr}
      />
    </Box>
  );
};

export default SelectMenu;
