import React from 'react';
import { Flex } from '@chakra-ui/core';
import { BeatLoader } from 'react-spinners';

/* The Component */
const OverlayLoader: React.FC = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex={100}
      background="rgba(255,255,255,.8)"
    >
      <BeatLoader color="pink" size="40" />
    </Flex>
  );
};

/* Runtime React propTypes */
OverlayLoader.propTypes = {};

export default OverlayLoader;
