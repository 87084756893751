import React, { useState, ReactElement } from 'react';
import { useQuery } from 'react-apollo';
import { Flex, Text, Box } from '@chakra-ui/core';

import { gql } from 'apollo-boost';
import Aria from 'components/Aria';
import BackgroundImage from 'components/BackgroundImage';
import MainBackgroundPNG from 'assets/images/mainBackground.png';
import { useMakeToast } from 'components/Toaster/Toaster';
import { PageContainer } from '../../components/Layout';
import SelectMenu from '../../components/Menus/SelectMenu';
import SchoolAccordion from './SchoolAccordion';
import Map from './Map';
import { School, Complex } from '../../typings';

const LIST_SCHOOLS = gql`
  query getSchools {
    getSchools {
      code
      name
      closed
      point {
        lat
        lng
      }
      complex {
        code
      }
    }
  }
`;

const LIST_COMPLEXES = gql`
  query getComplexes {
    getComplexes {
      code
      name
    }
  }
`;

const SchoolSearchPage = (): ReactElement => {
  const { data: schoolData, error: schoolsError, loading: schoolsLoading } = useQuery(LIST_SCHOOLS);
  const { data: complexData, error: complexesError, loading: complexesLoading } = useQuery(LIST_COMPLEXES);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [selectedComplex, setSelectedComplex] = useState<Complex | null>(null);
  const [visibleSchools, setVisibleSchools] = useState<School[]>([]);
  const [highlightSchool, setHighlightSchool] = useState<number | undefined>();
  const makeToast = useMakeToast();

  if (schoolsError || complexesError) {
    makeToast({
      status: 'danger',
      text: 'School and Complex Error',
      subText: 'there was an issue getting the school or complex list for this page.',
    });
  }

  const onSelectComplex = (selected: Complex | number): void => {
    const s: Partial<Complex> = typeof selected === 'number' ? { code: selected } : selected;

    const complex = complexData.getComplexes.find((c: Complex) => c.code === s.code);

    setSelectedSchool(null);
    setSelectedComplex(complex);
    setVisibleSchools(
      schoolData.getSchools.filter((school: School) => school.complex && school.complex.code === s.code),
    );
  };

  const onSelectSchool = (selected: School): void => {
    setSelectedComplex(null);

    const school = schoolData.getSchools.find((s: School) => s.code === selected.code);
    setVisibleSchools([school]);
    setSelectedSchool(school);
  };

  const onSelectCode = React.useCallback(onSelectComplex, [complexData, schoolData]);

  return (
    <PageContainer>
      <BackgroundImage src={MainBackgroundPNG}>
        <Flex flexWrap="wrap" paddingTop="4em">
          <Box flex="1" minWidth="350px">
            <Flex height="200px" justifyContent="center" alignItems="center" flexDirection="column">
              <Box paddingX=".25em">
                <Text as="h1" fontSize="2.7rem" color="white">
                  Schools
                </Text>
                <Text as="span" fontSize="1.2rem" color="white">
                  Search for a school by name or complex to find available reports
                </Text>
              </Box>
            </Flex>
            <Flex backgroundColor="accent" height="150px" alignItems="flex-end" padding="30px">
              <Aria.ContentAnchor />
              <SelectMenu
                label="Search by School Name"
                labelColor="white"
                value={selectedSchool}
                onSelectOption={onSelectSchool}
                options={(schoolData && schoolData.getSchools) || []}
                optionLabelKey="name"
                optionValueKey="code"
                isLoading={schoolsLoading}
                marginRight="30px"
              />
              <SelectMenu
                label="Search by Complex"
                labelColor="white"
                value={selectedComplex}
                onSelectOption={onSelectComplex}
                options={(complexData && complexData.getComplexes) || []}
                optionLabelKey="name"
                optionValueKey="code"
                isLoading={complexesLoading}
              />
            </Flex>
          </Box>
          <Box flex="1" minWidth="350px" minHeight="350px">
            <Map data={visibleSchools} onSelect={onSelectCode} onSchoolMarkerFocus={setHighlightSchool} />
          </Box>
        </Flex>
      </BackgroundImage>
      {/* List of Schools from Search: */}
      <Box flex="1" paddingX="1em" paddingY="2em">
        {visibleSchools.length > 0 ? (
          visibleSchools.map((school: School) => (
            <SchoolAccordion {...school} key={school.code} highlight={school.code === highlightSchool} />
          ))
        ) : (
          <Flex height="10rem" alignItems="center" justifyContent="center">
            <Text as="h2" fontSize="24px" color="gray.800">
              Search an area to get the schools
            </Text>
          </Flex>
        )}
      </Box>
    </PageContainer>
  );
};

export default SchoolSearchPage;
